/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

// src/views/components/TopNavBar.js

import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import Icon from "@mui/material/Icon";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import OnboardingBulbImage from "assets/images/onboarding-bulb.png";
import Box from "@mui/material/Box";
import {
  getAllPendingReviewsForUser,
  getAssignedTaskListResponsibilitiesForUser,
  fetchOnboardingProgressData,
} from "features/user/notificationActions";
import { updateCurrentClientSelected } from "../../../features/company/companyUsersActions";

import GamificationSidebar from "views/components/GamificationSidebar";
import "./top-nav-styles.css";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { useMaterialUIController, setDarkMode } from "context";
import { notifyUserAssignedToTaskListItem } from "features/company/taskListActions";
import { useCookies } from "react-cookie";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const NotificationPopup = ({
  open,
  onClose,
  pendingPolicyActionsCount,
  assignedTaskListItemResponsibilitiesForUser,
}) => {
  const { dark } = colors;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  const dispatch = useDispatch();

  const goToPendingReviewsPage = () => {
    window.location = "/pending-reviews";
  };

  const goToPendingAssetApprovalsPage = () => {
    window.location = "/assets/approvals";
  };

  const goToPendingWispApprovalsPage = () => {
    window.location = "/wisp/approvals";
  };

  const goToTaskListItemPage = (taskListId, taskListItemId) => {
    notifyUserResponsibleForTaskListItem(
      { objUser, taskListId, taskListItemId },
      () => {
        window.location = `/task-lists`;
      }
    );
  };

  const notifyUserResponsibleForTaskListItem = async (
    { objUser, taskListId, taskListItemId },
    callback
  ) => {
    dispatch(
      notifyUserAssignedToTaskListItem({
        objUser,
        taskListId,
        taskListItemId,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.data);
        if (callback) callback(); // Call the callback after the process is complete
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Notifications</DialogTitle>
      <DialogContent>
        {pendingPolicyActionsCount.pendingTotal === 0 ||
        assignedTaskListItemResponsibilitiesForUser.count === 0 ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            You have no notifications at the moment
          </MDTypography>
        ) : (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 500,
              marginTop: "1rem",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {pendingPolicyActionsCount.pendingReviewsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                  sx={{ backgroundColor: darkMode ? dark.main : "" }}
                >
                  <ListItemText
                    primary={`Pending Reviews (${pendingPolicyActionsCount.pendingReviewsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}

            {pendingPolicyActionsCount.pendingApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Approvals (${pendingPolicyActionsCount.pendingApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingLegalCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Legal Reviews (${pendingPolicyActionsCount.pendingLegalCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingAssetApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingAssetApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Asset Approvals (${pendingPolicyActionsCount.pendingAssetApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingWispApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingWispApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending WISP Approvals (${pendingPolicyActionsCount.pendingWispApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {assignedTaskListItemResponsibilitiesForUser.count > 0
              ? assignedTaskListItemResponsibilitiesForUser.assignedResponsibilities.map(
                  (responsibility) => (
                    <ListItem key={responsibility.id} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() =>
                          goToTaskListItemPage(
                            responsibility.task_list_id,
                            responsibility.id
                          )
                        }
                        dense
                      >
                        <ListItemText
                          primary={`You have been assigned to Task List Item: ${responsibility.title}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                )
              : ""}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

const NotificationBell = ({ darkMode }) => {
  const [controller] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const [pendingPolicyActionsCount, setPendingPolicyActionsCount] = useState({
    pendingReviewsCount: 0,
    pendingApprovalsCount: 0,
    pendingLegalCount: 0,
    pendingWispApprovalsCount: 0,
    pendingTotal: 0,
  });
  const [
    assignedTaskListItemResponsibilitiesForUser,
    setAssignedTaskListItemResponsibilitiesForUser,
  ] = useState({
    assignedResponsibilities: [],
    count: 0,
  });
  const dispatch = useDispatch();

  const showNotificationsDialog = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPendingReviewsForUser();
    fetchAssignedTaskListItemResponsibilitiesForUser();
  }, []);

  const fetchPendingReviewsForUser = () => {
    const _per_page = 100;
    const _page = 1;
    dispatch(getAllPendingReviewsForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setPendingPolicyActionsCount({
          pendingReviewsCount: response.data.pendingReviewsCount,
          pendingApprovalsCount: response.data.pendingApprovalsCount,
          pendingAssetApprovalsCount: response.data.pendingAssetApprovalsCount,
          pendingLegalCount: response.data.pendingLegalCount,
          pendingWispApprovalsCount: response.data.pendingWispApprovalsCount,
          pendingTotal:
            response.data.pendingReviewsCount +
            response.data.pendingApprovalsCount +
            response.data.pendingAssetApprovalsCount +
            response.data.pendingLegalCount +
            response.data.pendingWispApprovalsCount,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const fetchAssignedTaskListItemResponsibilitiesForUser = () => {
    const _per_page = 100;
    const _page = 1;
    dispatch(getAssignedTaskListResponsibilitiesForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setAssignedTaskListItemResponsibilitiesForUser({
          assignedResponsibilities:
            response.data.taskListItemsWithAssignedResponsibleUser,
          count: response.data.total,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  return (
    <>
      <NotificationPopup
        open={open}
        onClose={onClose}
        pendingPolicyActionsCount={pendingPolicyActionsCount}
        assignedTaskListItemResponsibilitiesForUser={
          assignedTaskListItemResponsibilitiesForUser
        }
      />
      <IconButton color="inherit" onClick={showNotificationsDialog}>
        <Badge
          badgeContent={
            pendingPolicyActionsCount.pendingTotal +
            assignedTaskListItemResponsibilitiesForUser.count
          }
          color="error"
        >
          <NotificationsIcon color={darkMode ? "white" : ""} />
        </Badge>
      </IconButton>
    </>
  );
};

const DarkModeToggle = (objUser) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const handleDarkMode = () => {
    setDarkMode(dispatch, !darkMode);
    localStorage.setItem("darkMode", !darkMode);
  };

  return (
    <>
      {darkMode ? (
        <IconButton onClick={handleDarkMode}>
          <LightModeIcon sx={{ color: "#fff" }} />
        </IconButton>
      ) : (
        <IconButton onClick={handleDarkMode}>
          <DarkModeIcon sx={{ color: "#fff" }} />
        </IconButton>
      )}
    </>
  );
};

/**onboarding bulb */
const OnboardingTask = ({ darkMode, parentTask, onboardingProgressData }) => {
  const subTasks =
    onboardingProgressData.onboardingChecklist[parentTask]?.subTasks ?? []; //extra check for parentTasks with no subTasks
  const subTasksTitlesArray = Object.keys(subTasks);

  return (
    <Accordion
      defaultExpanded={false}
      style={{
        marginBottom: "25px",
        borderRadius: "5px",
        marginTop: "16px",
      }}
      sx={
        darkMode
          ? { backgroundColor: "#f0f0f091" }
          : { backgroundColor: "#f0f0f0" }
      }
    >
      <AccordionSummary
        // sx={{ flexDirection: "row-reverse" }}
        sx={{ background: darkMode ? "#2f3958" : "#f0f0f091" }}
        expandIcon={<ExpandMoreIcon color="icon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <MDBox sx={{ display: "flex", gap: 1 }}>
          {onboardingProgressData.onboardingChecklist[parentTask]
            .parentTaskCompleted ? (
            <CheckCircleIcon
              sx={{
                // marginLeft: "auto",
                color: "#22A06B",
              }}
              fontSize="medium"
            />
          ) : (
            <ErrorIcon
              sx={{
                // marginLeft: "auto",
                color: "#FFBF00",
              }}
              fontSize="medium"
            />
          )}
          <MDTypography
            variant="h6"
            style={{
              fontSize: "1.05rem",
              color: darkMode ? "#fff" : "#344767",
            }}
          >
            {parentTask}
          </MDTypography>
        </MDBox>
      </AccordionSummary>
      <AccordionDetails>
        {subTasksTitlesArray.map((subTaskTitle) => {
          return (
            <>
              {subTasks[subTaskTitle].completed ? (
                <Card sx={{ padding: "0.5rem", mb: 1 }}>
                  <MDBox sx={{ display: "flex", gap: 1 }}>
                    <CheckCircleIcon
                      sx={{
                        color: subTasks[subTaskTitle].completed
                          ? "#38b54a"
                          : "#eee",
                      }}
                      fontSize="medium"
                    />
                    <MDTypography
                      variant="p"
                      style={{
                        fontSize: "1.05rem",
                        color: darkMode ? "whitefix" : "#344767",
                      }}
                    >
                      {subTaskTitle}
                    </MDTypography>
                  </MDBox>
                </Card>
              ) : (
                <Link to={subTasks[subTaskTitle].actionURL}>
                  <Card sx={{ padding: "0.5rem", mb: 1 }}>
                    <MDBox sx={{ display: "flex", gap: 1 }}>
                      <CheckCircleIcon
                        sx={{
                          color: subTasks[subTaskTitle].completed
                            ? "#38b54a"
                            : "#eee",
                        }}
                        fontSize="medium"
                      />
                      <MDTypography
                        variant="p"
                        style={{
                          fontSize: "1.05rem",
                          color: darkMode ? "whitefix" : "#344767",
                        }}
                      >
                        {subTaskTitle}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Link>
              )}
            </>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const OnboardingBulb = ({ darkMode }) => {
  const [open, setOpen] = useState(false);
  const [onboardingProgressData, setOnboardingProgressData] = useState(null);
  const [onboardingProgressParentTasks, setOnboardingProgressParentTasks] =
    useState([]);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["remindMeOfOnboardingLater"]);
  const onboardingStepsDivRef = useRef(null);

  useEffect(() => {
    fetchOnboardingProgressDataForUser();
    // if (cookies.remindMeOfOnboardingLater) {
    //   setOpen(false);
    // }
  }, []);

  useEffect(() => {
    if (
      onboardingStepsDivRef.current &&
      cookies.remindMeOfOnboardingLater === undefined
    ) {
      // setOpen(true);
    }
  }, [onboardingProgressData]);

  const fetchOnboardingProgressDataForUser = () => {
    dispatch(fetchOnboardingProgressData())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        console.log(response.data.onboardingProgressData);

        /**
         * We need to do some cleanup because the onboardingProgressData object from the backend is a
         * php associative array but during json encoding, all the values are converted to objects
         * Meaning if we just loop through the tasks, this will break
         */
        setOnboardingProgressParentTasks(
          Object.keys(response.data.onboardingProgressData.onboardingChecklist)
        );
        setOnboardingProgressData(response.data.onboardingProgressData);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const closeOnboardingPopupForAWhile = () => {
    setCookie("remindMeOfOnboardingLater", "yes", {
      path: "/",
      maxAge: 172800, //2days  (86400 per day)
    });
    setOpen(false);
  };

  return (
    <div className="onboardingBulbParentDiv" style={{ position: "relative" }}>
      {onboardingProgressData &&
      onboardingProgressData?.onboardingScoring?.numberOfUncompletedTasks >
        0 ? (
        <>
          {/* onboarding content */}
          <div
            id="onboardingStepsDiv"
            ref={onboardingStepsDivRef}
            style={{
              transform: open ? "scale(1)" : "scale(0)",
              backgroundColor: darkMode ? "#1a2035" : "#fff",
            }}
          >
            <div
              id="onboardingStepsDivHeader"
              style={{
                position: "relative",
              }}
            >
              <b>Get started with</b> <br />
              <b>Compliance Scorecard</b>
              <img
                style={{
                  position: "absolute",
                  width: "150px",
                  top: "18px",
                  right: 0,
                  opacity: "0.3",
                }}
                src={OnboardingBulbImage}
                alt="onboardingBulb"
              />
              <Box
                sx={{
                  width: "100%",
                  background: "rgba(255, 255, 255, 0.2)",
                  mt: 2,
                  position: "relative",
                  borderRadius: "20px",
                  height: "10px",
                }}
              >
                <LinearProgress
                  // color="inherit"
                  variant="determinate"
                  sx={{
                    width:
                      onboardingProgressData?.onboardingScoring
                        ?.totalPointsAsAPercentage,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#38b54a",
                      height: "10px",
                    },
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "-11px",
                    left:
                      onboardingProgressData?.onboardingScoring
                        ?.totalPointsAsAPercentage == "100%"
                        ? "95%"
                        : onboardingProgressData?.onboardingScoring
                            ?.totalPointsAsAPercentage,
                  }}
                >
                  <Badge
                    badgeContent={
                      onboardingProgressData?.onboardingScoring
                        ?.totalPointsAsAPercentage
                    }
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#38b54a",
                        color: "#fff",
                      },
                    }}
                  ></Badge>
                </span>
              </Box>
            </div>
            <div
              id="onboardingStepsContent"
              style={{
                backgroundColor: darkMode ? "#1a2035" : "#fff",
              }}
            >
              {onboardingProgressData &&
              onboardingProgressParentTasks.length > 0 ? (
                <>
                  {onboardingProgressParentTasks.map((parentTask, index) => {
                    return (
                      <OnboardingTask
                        key={index}
                        darkMode={darkMode}
                        parentTask={parentTask}
                        onboardingProgressData={onboardingProgressData}
                      />
                    );
                  })}
                </>
              ) : (
                ""
              )}

              {/* <MDButton
                type="button"
                variant="gradient"
                color="success"
                sx={{ padding: "12px 12px", width: "100%", mb: 2 }}
                onClick={() => closeOnboardingPopupForAWhile()}
              >
                Remind me later
              </MDButton> */}
            </div>
          </div>
          {/* icon button and badge */}
          <div>
            <IconButton
              color="inherit"
              onClick={() => setOpen((prev) => !prev)}
              sx={{
                marginTop: "3px",
                marginLeft: "5px",
              }}
            >
              {onboardingProgressData &&
              onboardingProgressParentTasks.length > 0 ? (
                <Badge
                  badgeContent={
                    onboardingProgressData.onboardingScoring
                      .numberOfUncompletedTasks
                  }
                  color="info"
                >
                  <LightbulbIcon color={darkMode ? "white" : ""} />
                </Badge>
              ) : (
                ""
              )}
            </IconButton>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default function TopNavBar({ topNavRoutes, miniSidenav }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });
  const [listOfClients, setListOfClients] = useState([]);
  const [viewDropDown, setViewDropDown] = useState("");
  const [switcherLoading, setswitcherLoading] = useState(false);
  const dispatchCurrentClientSelectedUpdate = useDispatch();

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openMenuKey, setOpenMenuKey] = useState(null);

  const handleMenuClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuKey(key);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuKey(null);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (objUser !== undefined && Object.keys(objUser).length > 0) {
      if (objUser.authorized && objUser.user.role.name !== "peer_group_admin") {
        setListOfClients(objUser.user.list_of_clients);
        if (objUser.user.role.name == "client_user") {
          setCurrentClient(objUser.user.list_of_clients);
          setViewDropDown(false);
        } else {
          let current_client = objUser.user?.list_of_clients?.find((item) => {
            return item.id === objUser.user.current_client_id;
          });
          setCurrentClient(current_client);
          setViewDropDown(true);
        }
      }
    }
  }, []);

  const backgroundValue = darkMode
    ? "#1f283e"
    : "linear-gradient(#032c4c, #053960)";
  const dropshadowValue = darkMode ? "0 5px 16px #0c101d" : "0 5px 10px #555";
  const dropdownMenuValue = darkMode ? "#ffffff" : "#0c314e";

  function handleClientSwitcherChange(event, value) {
    setCurrentClient(value);
    setswitcherLoading(true);
    dispatchCurrentClientSelectedUpdate(updateCurrentClientSelected({ value }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        window.location = "/home";
      })
      .catch((rejectedValueOrSerializedError) => {
        setswitcherLoading(false);
        console.log(rejectedValueOrSerializedError);
      });
  }

  return (
    <>
      <GamificationSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <AppBar
        className="top-header-menu"
        position="sticky"
        sx={{
          background: backgroundValue,
          color: "#fff",
          height: "75px",
          boxShadow: dropshadowValue,
        }}
      >
        <Toolbar
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",
            height: "75px",

            [breakpoints.up("xl")]: {
              marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          {/* Selected client switcher */}

          <MDBox mt={1.5} mb={1.5} pb={1} px={4} className="auto_c">
            <div
              className="switcher-overlay"
              style={{ display: `${switcherLoading ? "flex" : "none"}` }}
            >
              <LoadingSpinner></LoadingSpinner>
            </div>
            {viewDropDown == true ? (
              <Autocomplete
                disableClearable
                value={currentClient}
                options={listOfClients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Selected Company"
                    fullWidth
                    InputLabelProps={{
                      style: { color: "white", fontSize: "14px" }, // Apply custom styles to label
                    }}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        marginTop: "10px",
                      },
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={handleClientSwitcherChange}
              />
            ) : (
              <MDTypography variant="p" sx={{ color: "#fff" }}>
                {currentClient.name}
              </MDTypography>
            )}
          </MDBox>
          <MDBox color="inherit" sx={{ marginLeft: "auto", display: "flex" }}>
            <MDBox px={3} sx={{ color: "#fff", display: "flex" }}>
              {/* Rendering topNavRoutes */}
              {topNavRoutes.map((route) => {
                // Don't render if user doesn't have access
                if (
                  route?.clientPermissionRequired &&
                  !route?.clientPermissionRequired.some((item) =>
                    objUser.user.client_feature_access.includes(item)
                  ) &&
                  objUser.user.role.name !== "admin" &&
                  objUser.user.role.name !== "company_user"
                ) {
                  return false;
                }
                if (
                  route.canBeAccessedBy &&
                  !route.canBeAccessedBy?.includes(objUser.user.role.name)
                ) {
                  return null;
                }
                if (
                  route.cannotBeAccessedBy &&
                  route.cannotBeAccessedBy?.includes(objUser.user.role.name)
                ) {
                  return null;
                }

                if (route.collapse) {
                  return (
                    <>
                      <Typography
                        key={route.key}
                        variant="body1"
                        px={2}
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={(event) => handleMenuClick(event, route.key)}
                      >
                        {route.name}
                        <Icon sx={{ marginLeft: 1 }}>keyboard_arrow_down</Icon>
                      </Typography>

                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuKey === route.key}
                        onClose={handleMenuClose}
                      >
                        {route.collapse.map((collapseItem) => {
                          if (
                            collapseItem?.component?.props
                              ?.clientPermissionRequired &&
                            !collapseItem?.component?.props?.clientPermissionRequired.some(
                              (item) =>
                                objUser.user.client_feature_access.includes(
                                  item
                                )
                            ) &&
                            // !objUser.user.client_feature_access.includes(
                            //   collapseItem?.component?.props
                            //     ?.clientPermissionRequired[0]
                            // )
                            objUser.user.role.name !== "admin" &&
                            objUser.user.role.name !== "company_user"
                          ) {
                            return false;
                          }

                          if (
                            collapseItem.mustBeCompanyAdminOrCompanyUser &&
                            !(
                              objUser.user.role.name === "admin" ||
                              objUser.user.role.name === "company_user"
                            )
                          ) {
                            return null;
                          }
                          if (
                            collapseItem.mustBeClientUser &&
                            !(objUser.user.role.name === "client_user")
                          ) {
                            return null;
                          }

                          return (
                            <Link
                              key={collapseItem.key}
                              to={collapseItem.route}
                              style={{
                                color: dropdownMenuValue,
                                textDecoration: "none",
                                fontSize: "15px",
                              }}
                            >
                              <MenuItem
                                sx={{ borderRadius: "0", padding: "10px" }}
                              >
                                {collapseItem.name}
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </Menu>
                    </>
                  );
                }

                // Handle non-collapsible routes
                if (
                  route.mustBeCompanyAdminOrCompanyUser &&
                  !(
                    objUser.user.role.name === "admin" ||
                    objUser.user.role.name === "company_user"
                  )
                ) {
                  return null;
                }
                if (
                  route.mustBeClientUser &&
                  !(objUser.user.role.name === "client_user")
                ) {
                  return null;
                }

                return (
                  <Typography
                    key={route.key}
                    variant="body1"
                    px={2}
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={route.route}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {route.name}
                    </Link>
                  </Typography>
                );
              })}
            </MDBox>
            {/* new icons here */}
            {objUser?.user?.role?.name === "company_user" ||
            objUser?.user?.role?.name === "admin" ? (
              <Tooltip title="Onboarding Progress">
                <OnboardingBulb darkMode={darkMode} />
              </Tooltip>
            ) : (
              ""
            )}
            <Tooltip title="View Notifications">
              <NotificationBell darkMode={darkMode} />
            </Tooltip>
            <Tooltip title="Toggle Dark Mode">
              <DarkModeToggle objUser={objUser} />
            </Tooltip>
            {(objUser.user.role.name === "admin" ||
              objUser.user.role.name === "company_user") && (
              <Tooltip title="View LeaderBoard">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={toggleSidebar}
                >
                  <Icon fontSize="medium" sx={{ color: "#fff" }}>
                    bar_chart
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Logout">
              <Typography
                key="logout"
                variant="body1"
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/logout"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    position: "relative",
                    top: "3px",
                  }}
                >
                  <Icon fontSize="medium">logout</Icon>
                </Link>
              </Typography>
            </Tooltip>
          </MDBox>
        </Toolbar>
      </AppBar>
    </>
  );
}
