import DefaultCell from "views/components/TableCells/DefaultCell";
import CompanyNameBtnTr from "./CompanyNameBtnTr";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
const CustomerManagementDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "companyName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "company email",
      accessor: "companyEmail",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "company phone",
      accessor: "companyPhone",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Plan",
      accessor: "plan",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "max no. of clients",
      accessor: "maxClients",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of clients",
      accessor: "numberOfClients",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Payment Medium",
      accessor: "paymentMedium",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "renewal date",
      accessor: "renewalDate",
      Cell: ({ value, column, row }) => (
        <MDBox>
          <MDTypography
            variant="p"
            fontSize={12}
            sx={{
              color:
                row.original.expiresIn > 0 && row.original.expiresIn <= 30
                  ? "red"
                  : row.original.expiresIn > 30 && row.original.expiresIn <= 60
                  ? "orange"
                  : "#858531",
            }}
          >
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Expires In",
      accessor: "expiresIn",
      Cell: ({ value }) => (
        <MDBox>
          <MDTypography
            variant="p"
            fontSize={12}
            sx={{
              color:
                value > 0 && value <= 30
                  ? "red"
                  : value > 30 && value <= 60
                  ? "orange"
                  : "#858531",
            }}
          >
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "date joined",
      accessor: "dateJoined",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default CustomerManagementDataTableData;
