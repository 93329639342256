import React, { useState, useEffect, useCallback } from "react";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg, displayExceptionErrorMessage } from "utils/general";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  SortableTree,
  TreeItemComponentProps,
  SimpleTreeItemWrapper,
} from "dnd-kit-sortable-tree";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useConfirm } from "material-ui-confirm";

import DashboardActionButton from "views/components/DashboardActionButton";
import {
  Card,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  Icon,
} from "@mui/material";
import CreateTaskListDialog from "./components/CreateTaskListDialog";
import MDTypography from "components/MDTypography";
import CreateTaskListItemNewDialog from "./components/CreateTaskListItemDialog";
import {
  getTaskListTemplateCSV,
  deleteTaskList,
  updateTaskListItem,
  deleteTaskListItem,
} from "features/company/taskListActions";
import { companyUsersList } from "features/company/companiesActions";
import {
  getGlobalTaskListTemplates,
  publishGlobalTaskListTemplate,
  unPublishGlobalTaskListTemplate,
} from "features/company/superAdminActions";
import lowPriorityLevel from "assets/images/priority_levels/low.svg";
import mediumPriorityLevel from "assets/images/priority_levels/medium.svg";
import highPriorityLevel from "assets/images/priority_levels/high.svg";
import highestPriorityLevel from "assets/images/priority_levels/highest.svg";
import ViewOrEditTaskListDialog from "./components/ViewOrEditTaskListDialog";
import ViewOrEditTaskListItemDialog from "./components/ViewOrEditTaskListItemDialog";
import CSVTemplateImportDialog from "./components/CSVTemplateImportDialog";

const initialTaskLists = [
  {
    id: "1",
    title: "TaskList 1",
    notes: "Notes for TaskList 1",
    items: [
      {
        id: "1",
        value: "TaskListItem 1",
        children: [
          { id: "4", value: "TaskListItem 4" },
          { id: "5", value: "TaskListItem 5" },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "TaskList 2",
    notes: "Notes for TaskList 2",
    items: [
      {
        id: "2",
        value: "TaskListItem 2",
        children: [{ id: "6", value: "TaskListItem 6" }],
      },
      { id: "3", value: "TaskListItem 3", canHaveChildren: true },
    ],
  },
  {
    id: "3",
    title: "TaskList 3",
    notes: "Notes for TaskList 3",
    items: [],
  },
];

const GlobalTaskListTemplates = (props) => {
  const { objUser } = props;
  const [taskLists, setTaskLists] = useState([]);
  const [selectedTaskList, setSelectedTaskList] = useState(null);
  const [createTaskListDialogOpen, setCreateTaskListDialogOpen] =
    useState(false);
  const [viewOrEditTaskListDialogOpen, setViewOrEditTaskListDialogOpen] =
    useState(false);
  const [taskListItemDialogOpen, setTaskListItemDialogOpen] = useState(false);
  const [
    viewOrEditTaskListItemDialogOpen,
    setViewOrEditTaskListItemDialogOpen,
  ] = useState(false);
  const [currentTaskList, setCurrentTaskList] = useState(null);
  const [currentTaskListItem, setCurrentTaskListItem] = useState(null);
  const [isFetchingTaskList, setIsFetchingTaskList] = useState(true);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [taskListItemsUsers, setTaskListItemsUsers] = useState([]);
  const [isUpdatingTaskList, setIsUpdatingTaskList] = useState(false);
  const [isUpdatingTaskListItem, setIsUpdatingTaskListItem] = useState(false);
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

  const dispatch = useDispatch();
  const confirm = useConfirm();

  useEffect(() => {
    if (taskLists.length > 0 && !selectedTaskList) {
      setSelectedTaskList(taskLists[0]);
    }
  }, [taskLists, selectedTaskList]);

  useEffect(() => {
    setSelectedTaskList(null);
    getList();
  }, []);

  const normalizeTaskListItems = (taskList) => {
    if (!Array.isArray(taskList)) return [];

    return taskList.map((task) => ({
      ...task,
      task_list_items: task.task_list_items.map((item) => ({
        ...item,
        children: item.sub_tasks ? [...item.sub_tasks] : [],
      })),
    }));
  };

  const getList = async () => {
    try {
      setIsFetchingTaskList(true);
      const originalPromiseResult = await dispatch(
        getGlobalTaskListTemplates({ objUser })
      ).then(unwrapResult);

      if (!originalPromiseResult || originalPromiseResult.status !== 200) {
        throw new Error("Get task lists request failed.");
      }

      const taskLists = originalPromiseResult?.data?.taskLists;

      if (Array.isArray(taskLists)) {
        // Normalize task list items to avoid mutating original data
        const normalizedTaskLists = normalizeTaskListItems(taskLists);
        setTaskLists(normalizedTaskLists);
      } else {
        console.warn("Task lists are not an array:", taskLists);
      }
    } catch (error) {
      console.error("Error fetching task list:", error);
      showMsg("error", "Something went wrong, please try again.");
    } finally {
      try {
        setIsFetchingTaskList(false);
      } catch (error) {
        console.error("Error while setting fetching state:", error);
      }
    }
  };

  useEffect(() => {
    setIsFetchingUsers(true);
    const getUsers = async () => {
      try {
        const response = await dispatch(
          companyUsersList({
            objUser,
            _per_page: 1000,
            _page: 1,
          })
        );
        if (response.payload.status === 200) {
          setTaskListItemsUsers(response.payload.data.users);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      } catch (error) {
        showMsg("error", "Something went wrong, please try again");
      } finally {
        setIsFetchingUsers(false);
      }
    };
    getUsers();
  }, [dispatch, objUser]);

  const handleTaskListClick = (taskList) => {
    setSelectedTaskList(taskList);
    setCurrentTaskList(taskList);
  };

  const updateTaskListItemDispatch = async (id, updates) => {
    if (!id || !updates || !Object.keys(updates).length) {
      console.warn("Invalid task item update request", { id, updates });
      return;
    }

    try {
      setIsUpdatingTaskListItem(true);

      const objData = {
        id,
        ...updates,
      };

      const originalPromiseResult = await dispatch(
        updateTaskListItem({
          objUser,
          objData,
          taskListId: selectedTaskList?.id,
        })
      ).then(unwrapResult);

      const response = originalPromiseResult;

      if (response?.status === 201 || response?.status === 200) {
        showMsg("success", "TaskListItem updated successfully");
      } else {
        console.warn(
          "Unexpected status code from updateTaskListItem",
          response
        );
        showMsg(
          "warning",
          "Task item update succeeded, but status was not 201."
        );
      }
    } catch (error) {
      console.error("TaskListItem update failed:", error);

      const status = error?.status ?? 500;
      if (status === 401) {
        showMsg("error", "User is not authorized");
      } else if (status === 400) {
        showMsg("error", "Invalid request. Please check your input.");
      } else if (status >= 500) {
        showMsg("error", "Server error. Please try again later.");
      } else {
        showMsg("error", error?.message || "An unknown error occurred.");
      }
    } finally {
      setIsUpdatingTaskListItem(false);
    }
  };

  // Utility to update subtask IDs without mutating the original data
  const updateSubtaskIds = (items, parentId = null) => {
    if (!Array.isArray(items)) return [];

    return items.flatMap((item) => {
      const newItem = {
        ...item,
        is_subtask_of_id: parentId,
      };

      if (Array.isArray(item.children) && item.children.length > 0) {
        newItem.children = updateSubtaskIds(item.children, item.id);
      }

      return [newItem];
    });
  };

  /**
   * Handles reordering items within a single task list
   */
  const handleItemsChange = async (newItems) => {
    try {
      const updatedItems = updateSubtaskIds(newItems);
      const childItems = updatedItems
        .filter(
          (item) => Array.isArray(item.children) && item.children.length > 0
        ) // Filter items with children
        .flatMap((item) => item.children);
      const updatePromises = childItems.map((item) => {
        const updates = { is_subtask_of_id: item.is_subtask_of_id };
        return updateTaskListItemDispatch(item.id, updates); // Returns a promise
      });

      await Promise.all(updatePromises);

      setTaskLists((prevTaskLists) =>
        prevTaskLists.map((list) =>
          list.id === selectedTaskList.id
            ? { ...list, task_list_items: updatedItems }
            : list
        )
      );
      setSelectedTaskList((prevSelected) => ({
        ...prevSelected,
        task_list_items: updatedItems,
      }));
    } catch (error) {
      console.error("Error updating task list items:", error);
      showMsg("error", "Something went wrong while updating items.");
    }
  };

  const onCreateTaskListDialogClose = () => {
    setCurrentTaskList(null);
    setCreateTaskListDialogOpen(false);
  };

  const onCreateTaskListDialogSubmit = (taskList) => {
    setCurrentTaskList(null);
    setCreateTaskListDialogOpen(false);
    getList();
  };

  const onViewOrEditTaskListDialogClose = () => {
    setCurrentTaskList(null);
    setViewOrEditTaskListDialogOpen(false);
  };

  const onViewOrEditTaskListDialogSubmit = (taskList) => {
    setCurrentTaskList(null);
    setViewOrEditTaskListDialogOpen(false);
    getList();
  };

  const onTaskListItemDialogClose = () => {
    setCurrentTaskListItem(null);
    setTaskListItemDialogOpen(false);
  };

  const onTaskListItemDialogSubmit = (taskList) => {
    setCurrentTaskListItem(null);
    setTaskListItemDialogOpen(false);
    getList();
  };

  const onViewOrEditTaskListItemDialogClose = () => {
    setCurrentTaskListItem(null);
    setViewOrEditTaskListItemDialogOpen(false);
  };

  const onViewOrEditTaskListItemDialogSubmit = (taskListItem) => {
    setCurrentTaskListItem(taskListItem);
    setViewOrEditTaskListItemDialogOpen(false);
    getList();
  };

  const onImportCSVTemplateDialogClose = () => {
    setImportCSVDialogOpen(false);
  };

  const onImportCSVTemplateDialogSubmit = (csvTemplate) => {
    console.log(csvTemplate);
    setImportCSVDialogOpen(false);
    getList();
  };

  const deleteTaskListObj = async (dataFromActionBtn) => {
    setIsFetchingTaskList(true);
    dispatch(deleteTaskList({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingTaskList(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Campaign deleted");
        }
        getList();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingTaskList(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const btnOnClickDeleteTaskList = (data) => {
    confirm({
      description: "Do you want to delete this taskList?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteTaskListObj(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const deleteTaskListItemObj = async (dataFromActionBtn) => {
    setIsFetchingTaskList(true);
    dispatch(deleteTaskListItem({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingTaskList(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Campaign deleted");
        }
        getList();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingTaskList(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const btnOnClickDeleteTaskListItem = (data) => {
    confirm({
      description: "Do you want to delete this taskList?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteTaskListItemObj(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleViewTaskListItemClicked = (item) => {
    setCurrentTaskListItem(item);
    setViewOrEditTaskListItemDialogOpen(true);
  };

  const getPrioritySymbol = (priorityLevel) => {
    const priorityMap = {
      low: lowPriorityLevel,
      medium: mediumPriorityLevel,
      high: highPriorityLevel,
      highest: highestPriorityLevel,
    };
    return priorityMap[priorityLevel] ?? "?";
  };

  const handleCheckboxChange = useCallback(
    async (event, itemId) => {
      const isChecked = event.target.checked;

      // Optimistic UI update (Optional: Can be moved to after successful update)
      setTaskLists((prevTaskLists) =>
        prevTaskLists.map((list) =>
          list.id === selectedTaskList.id
            ? {
                ...list,
                task_list_items: list.task_list_items.map((item) =>
                  item.id === itemId
                    ? { ...item, status: isChecked ? "Completed" : "ToDo" }
                    : item
                ),
              }
            : list
        )
      );

      // Set "updating" state
      setIsUpdatingTaskListItem(true);

      try {
        const updates = {
          status: isChecked ? "Completed" : "ToDo",
        };

        const response = await dispatch(
          updateTaskListItem({
            objUser,
            objData: { id: itemId, ...updates },
            taskListId: selectedTaskList.id,
          })
        ).then(unwrapResult);

        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Task status updated successfully.");
        } else {
          throw new Error("Task status update failed.");
        }
      } catch (error) {
        console.error("Error updating task status:", error);
        showMsg("error", error.message || "An unexpected error occurred");

        // Roll back optimistic UI update if necessary
        setTaskLists((prevTaskLists) =>
          prevTaskLists.map((list) =>
            list.id === selectedTaskList.id
              ? {
                  ...list,
                  task_list_items: list.task_list_items.map((item) =>
                    item.id === itemId
                      ? { ...item, status: isChecked ? "ToDo" : "Completed" }
                      : item
                  ),
                }
              : list
          )
        );
      } finally {
        setIsUpdatingTaskListItem(false);
      }
    },
    [dispatch, selectedTaskList?.id]
  );

  const [updatingTaskListIds, setUpdatingTaskListIds] = useState({});

  /**
   * Handles publishing and unpublishing of the task list
   * @param {Function} actionFn - The dispatch action function (publish/unpublish)
   * @param {object} taskList - The task list being updated
   * @param {string} successMessage - Success message to display on success
   */
  const handleTaskListAction = async (actionFn, taskList, successMessage) => {
    const taskListId = taskList.id;

    // Set loading state only for the specific task list
    setUpdatingTaskListIds((prevState) => ({
      ...prevState,
      [taskListId]: true, // Set this specific task list to "updating"
    }));

    try {
      const response = await dispatch(
        actionFn({
          objUser,
          taskListId: taskListId,
        })
      ).then(unwrapResult);

      if (response.status === 201 || response.status === 200) {
        showMsg("success", successMessage);

        // Update only the specific task list that was affected
        setTaskLists((prevTaskLists) =>
          prevTaskLists.map((list) =>
            list.id === taskListId
              ? { ...list, status: response?.data?.status || list.status } // Update status
              : list
          )
        );
      } else {
        showMsg("error", "Something went wrong, please try again");
      }
    } catch (error) {
      console.error("Error during task list action:", error);
      const errorMessage =
        error?.message || "Something went wrong, please try again";
      showMsg("error", errorMessage);
    } finally {
      // Remove the loading state for the specific task list
      setUpdatingTaskListIds((prevState) => ({
        ...prevState,
        [taskListId]: false, // Unset this specific task list's "updating" state
      }));
    }
  };

  /**
   * Publishes the task list
   */
  const publishTaskList = async (taskList) => {
    await handleTaskListAction(
      publishGlobalTaskListTemplate,
      taskList,
      "Task list published successfully"
    );
  };

  /**
   * Unpublishes the task list
   */
  const unPublishTaskList = async (taskList) => {
    await handleTaskListAction(
      unPublishGlobalTaskListTemplate,
      taskList,
      "Task list unpublished successfully"
    );
  };

  const exportTemplates = () => {
    setIsExporting(true);
    dispatch(getTaskListTemplateCSV({ objUser, scope: "global" }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsExporting(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `TaskListTemplate.csv`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsExporting(false);
        //  showMsg("error", "Something went wrong, please try again.");
        showMsg("error", rejectedValueOrSerializedError.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid container width="100%" paddingTop={1}>
        <Grid item xs={12} lg={12}>
          <MDBox
            display="flex"
            gap=".7rem"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={2}
          >
            <MDTypography variant="h4">TaskList Templates</MDTypography>
            <MDBox display="flex" gap=".7rem">
              <DashboardActionButton
                action={() => setImportCSVDialogOpen(true)}
                btnText="Import CSV"
                btnIcon="file_upload"
                textColor="white"
                bgColor="info"
              />
              <DashboardActionButton
                action={() => setCreateTaskListDialogOpen(true)}
                btnText="Create Template"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
              {isExporting ? (
                <LoadingSpinner color="success" size="md" />
              ) : (
                <DashboardActionButton
                  action={() => exportTemplates()}
                  btnText="Download Template CSV"
                  btnIcon="save"
                  textColor="white"
                  bgColor="success"
                />
              )}
            </MDBox>
          </MDBox>
        </Grid>

        {isFetchingTaskList ? (
          <MDBox mt={2}>
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          </MDBox>
        ) : (
          <>
            <Grid item xs={3}>
              <Card
                elevation={0}
                sx={{
                  border: "none",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  boxShadow: "none",
                }}
              >
                <MDBox>
                  {taskLists.length === 0 ? (
                    <MDBox p={2} textAlign="center">
                      <strong>Please create a Task List</strong>
                    </MDBox>
                  ) : (
                    taskLists.map((taskList) => (
                      <MDBox
                        key={taskList.id}
                        onClick={() => handleTaskListClick(taskList)}
                        style={{
                          cursor: "pointer",
                          padding: "8px",
                          backgroundColor:
                            selectedTaskList?.id === taskList.id
                              ? "#fff"
                              : "#f0f0f0",
                        }}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MDBox flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              mb={0.2}
                              sx={{
                                color: "#2c3e50",
                                textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                              }}
                            >
                              {taskList?.title}
                            </MDTypography>
                            <MDTypography variant="caption" color="text">
                              {taskList?.notes}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            {updatingTaskListIds[taskList.id] ? ( // Check if this taskList is being updated
                              <MDBox mr={1}>
                                <LoadingSpinner
                                  subClass="text-center"
                                  color="success"
                                  size="sm"
                                />
                              </MDBox>
                            ) : (
                              <Tooltip
                                title={`${
                                  taskList?.status === "Draft"
                                    ? "Publish"
                                    : "Unpublish"
                                } TaskList`}
                              >
                                <MDBox
                                  color={
                                    taskList?.status === "Draft"
                                      ? "success"
                                      : "error"
                                  }
                                  display="flex"
                                  alignItems="center"
                                  onClick={() =>
                                    taskList?.status === "Draft"
                                      ? publishTaskList(taskList)
                                      : unPublishTaskList(taskList)
                                  }
                                  sx={{ marginRight: "4px", cursor: "pointer" }}
                                >
                                  <Icon
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {taskList?.status === "Draft"
                                      ? "publish"
                                      : "do_not_disturb"}
                                  </Icon>
                                </MDBox>
                              </Tooltip>
                            )}

                            <MDBox
                              color="info"
                              display="flex"
                              alignItems="center"
                              onClick={() =>
                                setViewOrEditTaskListDialogOpen(true)
                              }
                              sx={{ marginRight: "4px" }}
                            >
                              <Icon
                                sx={{ fontWeight: "bold", fontSize: "1rem" }}
                              >
                                visibility
                              </Icon>
                            </MDBox>
                            <MDBox
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon
                                sx={{ fontWeight: "bold" }}
                                onClick={() =>
                                  btnOnClickDeleteTaskList(taskList)
                                }
                              >
                                delete
                              </Icon>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    ))
                  )}
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={9}>
              <Card
                elevation={0}
                sx={{
                  border: "none",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  boxShadow: "none",
                }}
              >
                {taskLists.length === 0 ? (
                  <MDBox p={4} textAlign="center">
                    <strong>Please create a Task List to get started</strong>
                  </MDBox>
                ) : selectedTaskList ? (
                  <MDBox m={2}>
                    {selectedTaskList?.task_list_items?.length === 0 ? (
                      <MDBox p={2} textAlign="center">
                        <strong>Please add Task List Items</strong>
                      </MDBox>
                    ) : (
                      <>
                        {isUpdatingTaskListItem ? (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        ) : (
                          <SortableTree
                            items={selectedTaskList.task_list_items || []}
                            onItemsChanged={handleItemsChange}
                            TreeItemComponent={React.forwardRef(
                              (props, ref) => (
                                <SimpleTreeItemWrapper {...props} ref={ref}>
                                  <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={2}
                                    sx={{
                                      backgroundColor: "#f5f5f5",
                                      borderRadius: "8px",
                                      width: "100%",
                                    }}
                                  >
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      sx={{ flex: 3 }}
                                    >
                                      <MDTypography
                                        variant="body1"
                                        fontWeight="bold"
                                        ml={1}
                                      >
                                        {props.item.title}
                                      </MDTypography>
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      sx={{ flex: 1, justifyContent: "center" }}
                                    >
                                      <MDTypography
                                        variant="body1"
                                        fontWeight="regular"
                                      >
                                        |
                                      </MDTypography>
                                    </MDBox>

                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      sx={{
                                        flex: 1,
                                        justifyContent: "center",
                                        marginRight: "15px",
                                      }}
                                    >
                                      <MDTypography
                                        variant="body1"
                                        fontWeight="bold"
                                        mr={1}
                                      >
                                        Priority:
                                      </MDTypography>
                                      <Tooltip
                                        title={props.item.priority_level}
                                      >
                                        <MDBox
                                          component="img"
                                          src={getPrioritySymbol(
                                            props.item.priority_level
                                          )}
                                          alt={props.item.priority_level}
                                          width="24px"
                                          height="24px"
                                          sx={{ opacity: 0.9, ml: 1 }}
                                        />
                                      </Tooltip>
                                    </MDBox>

                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      sx={{ flex: 1, justifyContent: "center" }}
                                    >
                                      <MDTypography
                                        variant="body1"
                                        fontWeight="regular"
                                      >
                                        |
                                      </MDTypography>
                                    </MDBox>

                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      sx={{
                                        flex: 2,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <MDTypography
                                        variant="body1"
                                        fontWeight="bold"
                                        mr={1}
                                      >
                                        Responsible:
                                      </MDTypography>
                                      <MDTypography
                                        variant="body"
                                        color="secondary"
                                        fontWeight="medium"
                                      >
                                        <Tooltip
                                          title={`${props?.item?.responsible_user?.name} ${props?.item?.responsible_user?.last_name}`}
                                        >
                                          {props.item.responsible_user
                                            ? `${props?.item?.responsible_user?.name}`
                                            : ""}
                                        </Tooltip>
                                      </MDTypography>
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{
                                        flex: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <MDBox
                                        color="info"
                                        display="flex"
                                        alignItems="center"
                                        onClick={() =>
                                          handleViewTaskListItemClicked(
                                            props.item
                                          )
                                        }
                                        sx={{
                                          marginRight: "4px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Icon
                                          sx={{
                                            fontWeight: "bold",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          visibility
                                        </Icon>
                                      </MDBox>
                                      <MDBox
                                        color="error"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Icon
                                          sx={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            btnOnClickDeleteTaskListItem(
                                              props.item
                                            )
                                          }
                                        >
                                          delete
                                        </Icon>
                                      </MDBox>
                                    </MDBox>
                                  </MDBox>
                                </SimpleTreeItemWrapper>
                              )
                            )}
                          />
                        )}
                      </>
                    )}
                    <MDBox display="flex" justifyContent="end" m={2}>
                      <DashboardActionButton
                        action={() => setTaskListItemDialogOpen(true)}
                        btnText="Add New TaskList Item"
                        btnIcon="add"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox p={4} textAlign="center">
                    <strong>Please select a Task List</strong>
                  </MDBox>
                )}
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <CreateTaskListDialog
        open={createTaskListDialogOpen}
        onClose={onCreateTaskListDialogClose}
        onSubmit={onCreateTaskListDialogSubmit}
        objUser={objUser}
        taskList={currentTaskList}
        taskLists={taskLists}
      />

      <CreateTaskListItemNewDialog
        open={taskListItemDialogOpen}
        onClose={onTaskListItemDialogClose}
        onSubmit={onTaskListItemDialogSubmit}
        objUser={objUser}
        taskList={selectedTaskList}
        taskListItems={selectedTaskList?.task_list_items}
        taskListItemUsers={taskListItemsUsers}
      />

      <ViewOrEditTaskListDialog
        open={viewOrEditTaskListDialogOpen}
        onClose={onViewOrEditTaskListDialogClose}
        onSubmit={onViewOrEditTaskListDialogSubmit}
        objUser={objUser}
        taskList={currentTaskList}
      />

      <ViewOrEditTaskListItemDialog
        open={viewOrEditTaskListItemDialogOpen}
        onClose={onViewOrEditTaskListItemDialogClose}
        onSubmit={onViewOrEditTaskListItemDialogSubmit}
        objUser={objUser}
        taskListItem={currentTaskListItem}
        taskListItems={selectedTaskList?.task_list_items}
        taskListItemsUsers={taskListItemsUsers}
        taskListId={selectedTaskList?.id}
        isFetchingUsers={isFetchingUsers}
      />
      <CSVTemplateImportDialog
        open={importCSVDialogOpen}
        onClose={onImportCSVTemplateDialogClose}
        onSubmit={onImportCSVTemplateDialogSubmit}
        objUser={objUser}
      />
    </DashboardLayout>
  );
};

export default GlobalTaskListTemplates;
