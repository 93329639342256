import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import { unwrapResult } from "@reduxjs/toolkit";
import assetImageLogo from "assets/images/asset-preview.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  getConnectorAssets,
  getPreviousSyncInformation,
  importConnectorAssets,
  saveSyncFrequencySettings,
} from "features/msgraph/actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

const MSGraphAssetSyncSection = (props) => {
  const navigate = useNavigate();
  const { objUser, type, assetLineRenderer, columns } = props;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const [frequencyType, setFrequencyType] = useState("");
  const [isUpdatingCurrentClient, setIsUpdatingCurrentClient] = useState(false);
  const [isLoadingSyncInfo, setIsLoadingSyncInfo] = useState(false);
  const [isSavingSyncSettings, setIsSavingSyncSettings] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [syncRecords, setSyncRecords] = useState(null);
  const [isFetchingAssets, setIsFetchingAssets] = useState(false);
  const [isImportingAssets, setIsImportingAssets] = useState(false);
  const [totalAssetsFetched, setTotalAssetsFetched] = useState(0);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    // if (
    //   objUser?.user?.role?.name !== "admin" &&
    //   objUser?.user?.role?.name !== "company_user"
    // ) {
    //   navigate(`/home`, {
    //     state: { routeDictionary },
    //   });
    // }
    fetchPreviousSyncInformation();
  }, []);

  const fetchPreviousSyncInformation = async () => {
    setIsLoadingSyncInfo(true);
    dispatch(getPreviousSyncInformation({ type }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSyncInfo(false);
        const response = originalPromiseResult;
        setSyncRecords(response.data);
        setSelectedValue(
          response.data.frequencyOfSync === ","
            ? ""
            : response.data.frequencyOfSync
        );
        setFrequencyType(response.data.frequencyOfSync.split(",")[0]);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSyncInfo(false);
        showMsg("error", "Recent sync records could not be fetched");
        console.error(rejectedValueOrSerializedError);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveSyncSettings = async () => {
    setIsSavingSyncSettings(true);
    dispatch(
      saveSyncFrequencySettings({
        selectedValue,
        type,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingSyncSettings(false);
        showMsg("success", "Sync frequency settings updated");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingSyncSettings(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error saving changes");
        }
      });
  };

  const fetchAssetsFromConnector = async () => {
    setIsFetchingAssets(true);
    dispatch(getConnectorAssets({ type }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const assetsResponseData = response.data;
        const totalAssets = response.data?.length;

        if (assetsResponseData.length > 0) {
          setAssetData(assetsResponseData);
          setTotalAssetsFetched(totalAssets);
        } else {
          showMsg("info", "No assets were found");
        }

        setIsFetchingAssets(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAssets(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.message
          );
        } else {
          showMsg("error", "Error fetching assets from MSGraph");
        }
      });
  };

  const importAssets = async () => {
    setIsImportingAssets(true);

    dispatch(importConnectorAssets({ assetData, totalAssetsFetched, type }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        navigate(
          `/asset-lists/${originalPromiseResult.data.finalPolicyObject.id}/${originalPromiseResult.data.updatedMajorVersion}`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsImportingAssets(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error importing assets");
        }
      });
  };

  const openAssetDocument = async () => {
    routeDictionary[syncRecords.policyId] = "Asset List (MS Graph)";
    navigate(
      `/policies/${syncRecords.policyId}/${syncRecords.documentLastMajorVersion}`,
      { state: { routeDictionary } }
    );
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container width="100%" spacing={2}>
        <Grid item xs={12} lg={12}>
          <MDBox bgColor="success" borderRadius="lg" mb={1} textAlign="center">
            <MDTypography variant="h4" color="white">
              {syncRecords ? syncRecords.clientName : "--"} using MS Graph
            </MDTypography>
          </MDBox>
          {isUpdatingCurrentClient ? (
            <MDBox mb={1}>
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            </MDBox>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              padding: "1rem",
              maxHeight: "600px",
            }}
          >
            <MDTypography variant="h6" color="success">
              Frequency of sync (select one option)
            </MDTypography>
            <Grid container>
              <Grid xs={6}>
                <label htmlFor={"weekly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"weekly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "weekly" }}
                      onChange={() => {
                        setFrequencyType("weekly");
                      }}
                      id={"weekly"}
                      checked={frequencyType === "weekly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Weekly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"monthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"monthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "monthly" }}
                      onChange={() => {
                        setFrequencyType("monthly");
                      }}
                      id={"monthly"}
                      checked={frequencyType === "monthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"bimonthly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"bimonthly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "bimonthly" }}
                      onChange={() => {
                        setFrequencyType("bimonthly");
                      }}
                      id={"bimonthly"}
                      checked={frequencyType === "bimonthly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Bi-monthly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
              <Grid xs={6}>
                <label htmlFor={"quarterly"}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="5px"
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      value={"quarterly"}
                      name={"frequencyType"}
                      inputProps={{ "aria-label": "quarterly" }}
                      onChange={() => {
                        setFrequencyType("quarterly");
                      }}
                      id={"quarterly"}
                      checked={frequencyType === "quarterly"}
                    />
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Quarterly
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </label>
              </Grid>
            </Grid>

            {frequencyType == "weekly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Weekly">Weekly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Weekly"
                    label="Weekly"
                    id="Weekly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="weekly,Monday">Monday</MenuItem>
                    <MenuItem value="weekly,Tuesday">Tuesday</MenuItem>
                    <MenuItem value="weekly,Wednesday">Wednesday</MenuItem>
                    <MenuItem value="weekly,Thursday">Thursday</MenuItem>
                    <MenuItem value="weekly,Friday">Friday</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "monthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Monthly">Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Monthly"
                    label="Monthly"
                    id="Monthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="monthly,1">
                      1st day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,15">
                      15th day of every Month
                    </MenuItem>
                    <MenuItem value="monthly,31">
                      Last day of every Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "bimonthly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Bimonthly">Bi-Monthly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Bimonthly"
                    label="Bimonthly"
                    id="Bimonthly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="bimonthly,1">
                      1st day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,15">
                      15th day every two Months
                    </MenuItem>
                    <MenuItem value="bimonthly,31">
                      Last day every two months
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {frequencyType == "quarterly" && (
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Quarterly">Quarterly on</InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Quarterly"
                    label="Quarterly"
                    id="Quarterly"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="quarterly,1">
                      1st day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,15">
                      15th day of January, April, July and October
                    </MenuItem>
                    <MenuItem value="quarterly,31">
                      Last day of January, April, July and October
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            )}

            {isSavingSyncSettings || isLoadingSyncInfo ? (
              <MDBox mt={3}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            ) : (
              <MDButton
                type="submit"
                // variant="gradient"
                color="success"
                disabled={syncRecords && syncRecords.lastSyncAt == null}
                onClick={saveSyncSettings}
                sx={{ padding: "12px 20px", marginTop: "1rem" }}
              >
                {syncRecords && syncRecords.lastSyncAt == null
                  ? "Initial asset sync required"
                  : "Save Sync Frequency Settings"}
              </MDButton>
            )}

            {syncRecords ? (
              <>
                {" "}
                <MDTypography variant="h6" color="dark" mt={4}>
                  Last sync:{" "}
                  {syncRecords.lastSyncAt
                    ? syncRecords.lastSyncAt
                    : "No initial sync yet"}
                </MDTypography>
                <MDTypography variant="h6" color="dark" mt={1}>
                  {syncRecords.numberOfAssetsSynced
                    ? "Synced " +
                      syncRecords.numberOfAssetsSynced +
                      " asset records"
                    : "No records synced"}
                </MDTypography>
                <MDTypography variant="h6" color="success" mt={1}>
                  {syncRecords.documentLastMajorVersion ? (
                    <MDButton
                      onClick={openAssetDocument}
                      variant="gradient"
                      color="dark"
                    >
                      View document
                    </MDButton>
                  ) : (
                    // </Link>
                    "N/A"
                  )}
                </MDTypography>
              </>
            ) : (
              ""
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {assetData.length === 0 ? (
              <>
                <MDBox mb={2}>
                  <img src={assetImageLogo} width="90px" alt="Assets" />
                </MDBox>
                <MDBox>
                  <MDButton
                    type="submit"
                    color="success"
                    onClick={fetchAssetsFromConnector}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Sync | Preview and Import Assets
                  </MDButton>
                </MDBox>
                {isFetchingAssets ? (
                  <MDBox mt={2}>
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  </MDBox>
                ) : (
                  ""
                )}
                {/* preview assets image and button end*/}
              </>
            ) : (
              <TableContainer sx={{ padding: "1rem" }}>
                {isImportingAssets ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    onClick={importAssets}
                    sx={{ padding: "12px 20px", margin: "0px 5px" }}
                  >
                    Import Assets
                  </MDButton>
                )}
                <Table>
                  <TableHead sx={{ display: "table-row-group" }}>
                    <TableRow
                      sx={{
                        "& td, & th": {
                          color: "text.main",
                        },
                      }}
                    >
                      {columns.map((column, i) => (
                        <TableCell key={i} component="th">
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>{assetData.map(assetLineRenderer)}</TableBody>
                </Table>
              </TableContainer>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const MSGraphAssetSyncSectionHardware = (props) => {
  const assetLineRenderer = (asset, i) => {
    return (
      <TableRow
        key={i}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
          "& td, & th": {
            color: "text.main",
          },
        }}
      >
        <TableCell>{asset.id || "N/A"}</TableCell>
        <TableCell>{asset.deviceId || "N/A"}</TableCell>
        <TableCell>{asset.displayName || "N/A"}</TableCell>
        <TableCell>{asset.deviceOwnership || "N/A"}</TableCell>
        <TableCell>{asset.operatingSystem || "N/A"}</TableCell>
        <TableCell>{asset.operatingSystemVersion || "N/A"}</TableCell>
        <TableCell>{asset.deviceCategory || "N/A"}</TableCell>
        <TableCell>{asset.manufacturer || "N/A"}</TableCell>
        <TableCell>{asset.model || "N/A"}</TableCell>
        <TableCell>
          {asset.approximateLastSignInDateTime
            ? new Date(
                asset.approximateLastSignInDateTime
              ).toLocaleDateString() +
              " " +
              new Date(asset.approximateLastSignInDateTime).toLocaleTimeString()
            : "N/A"}
        </TableCell>
        <TableCell>
          {asset.isManaged !== undefined
            ? asset.isManaged
              ? "Yes"
              : "No"
            : "N/A"}
        </TableCell>
        <TableCell>
          {asset.isCompliant !== undefined
            ? asset.isCompliant
              ? "Yes"
              : "No"
            : "N/A"}
        </TableCell>
        <TableCell>
          {asset.complianceExpirationDateTime
            ? new Date(asset.complianceExpirationDateTime).toLocaleDateString()
            : "N/A"}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <MSGraphAssetSyncSection
      {...props}
      type="hardware"
      assetLineRenderer={assetLineRenderer}
      columns={[
        "Id",
        "Device Id",
        "Display Name",
        "Device Ownership",
        "Operating System",
        "Operating System Version",
        "Device Category",
        "Manufacturer",
        "Model",
        "Last Sign In",
        "Managed",
        "Compliant",
        "Compliance Expiration",
      ]}
    />
  );
};

const MSGraphAssetSyncSectionData = (props) => {
  return <MSGraphAssetSyncSection {...props} type="data" />;
};

const MSGraphAssetSyncSectionPeople = (props) => {
  const assetLineRenderer = (asset, i) => {
    return (
      <TableRow
        key={i}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
          "& td, & th": {
            color: "text.main",
          },
        }}
      >
        <TableCell>{asset.id || "N/A"}</TableCell>
        <TableCell>{asset.displayName || "N/A"}</TableCell>
        <TableCell>{asset.mail || "N/A"}</TableCell>
        <TableCell>{asset.userPrincipalName || "N/A"}</TableCell>
        <TableCell>{asset.jobTitle || "N/A"}</TableCell>
        <TableCell>{asset.department || "N/A"}</TableCell>
        <TableCell>{asset.officeLocation || "N/A"}</TableCell>
        <TableCell>
          {asset.accountEnabled !== undefined
            ? asset.accountEnabled
              ? "Yes"
              : "No"
            : "N/A"}
        </TableCell>
        <TableCell>
          {Array.isArray(asset.assignedLicenses) ? (
            <ul>
              {asset.assignedLicenses.map((x) => (
                <li key={x} style={{ listStyleType: "disc" }}>
                  {x}
                </li>
              ))}
            </ul>
          ) : (
            asset.assignedLicenses || "N/A"
          )}
        </TableCell>
        <TableCell>
          {Array.isArray(asset.authMethods) ? (
            <ul
              style={{
                color: asset.authMethods.length < 2 ? "red" : "inherit",
              }}
            >
              {asset.authMethods.map((x) => (
                <li key={x} style={{ listStyleType: "disc" }}>
                  {x}
                </li>
              ))}
            </ul>
          ) : (
            <MDTypography variant="body2" color="error">
              {asset.authMethods || "N/A"}
            </MDTypography>
          )}
        </TableCell>
        <TableCell>
          {asset.lastPasswordChangeDateTime
            ? new Date(asset.lastPasswordChangeDateTime).toLocaleDateString() +
              " " +
              new Date(asset.lastPasswordChangeDateTime).toLocaleTimeString()
            : "N/A"}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <MSGraphAssetSyncSection
      {...props}
      type="human"
      assetLineRenderer={assetLineRenderer}
      columns={[
        "Id",
        "Display Name",
        "e-Mail",
        "User Principal Name",
        "Job Title",
        "Department",
        "Office Location",
        "Account Enabled",
        "Assigned Licenses",
        "Authentication Methods",
        "Last Password Change",
      ]}
    />
  );
};

export {
  MSGraphAssetSyncSectionData,
  MSGraphAssetSyncSectionHardware,
  MSGraphAssetSyncSectionPeople,
};
