import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchGamifycationStatsSAT } from "features/company/companiesActions";
import { useTheme } from "@mui/material/styles";
import "./charts.css";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import SymbolLogo from "assets/images/logos/symbol-logo.png";
import HuntressLogo from "assets/images/logos/huntress-logo.png";
import { borderBottom } from "@mui/system";
import { Icon } from "@mui/material";

Chart.register(ArcElement, Tooltip, Legend);

const chartLabels = ["Pending", "Completed", "Overdue"];
const bgColors = ["#ffa600", "#38c273", "#e3352e"];

const defaultChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "white",
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var dataset = context.dataset;
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[context.dataIndex];
          var percentage = ((currentValue / total) * 100).toFixed(0) + "%";
          return `${context.label}: ${currentValue} of ${total} (${percentage})`;
        },
      },
    },
  },
};

export default function SatCharts({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const [percent, setInsurancePercent] = useState(0);
  const [isMember, setIsMember] = useState(true);

  const chartOptions = useMemo(() => {
    const result = defaultChartOptions;
    result.plugins.legend.labels.color = theme.palette?.text?.main;
    return result;
  }, [theme.palette?.text?.main]);

  const dispatch = useDispatch();
  const getStats = () => {
    dispatch(fetchGamifycationStatsSAT({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        let data = originalPromiseResult.data;
        setObjGamifycationStats(data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <>
        {objGamifycationStats.symbol != null && (
          <MDBox mb={2}>
            {/* charts */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Card>
                <MDBox display="flex" px={0} pt={0}>
                  <MDBox
                    mt={0}
                    pt={2}
                    pl={2}
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography fontWeight="bold" variant="h5" color="dark">
                      Security Awareness Training by Symbol{" "}
                    </MDTypography>
                    <img
                      alt="Symbol"
                      style={{ maxWidth: "70px", marginLeft: "1rem" }}
                      src={SymbolLogo}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="space-around">
                  <MDBox xs={12} sm={4} p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Training Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.symbol.pendingAssignments,
                              objGamifycationStats.symbol.completedAssignments,
                              objGamifycationStats.symbol.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </MDBox>

                  <MDBox xs={12} sm={8} p={2}>
                    <MDTypography
                      fontWeight="bold"
                      variant="body2"
                      color="dark"
                      textAlign="center"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Phishing Simulation Stats
                    </MDTypography>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {/* Emails Sent */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Emails Sent
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.emailsSent}
                        </MDTypography>
                      </Grid>

                      {/* Phish Clicks */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Phish Clicks
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.phishClicks}
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Shared Data */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Shared Data
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.sharedData}
                          {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Rate */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.clickRate}%
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Factor */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Factor
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.clickFactor}x
                          <sup
                            style={{
                              color:
                                objGamifycationStats.symbol.riskStatus ===
                                "FAIR"
                                  ? "green"
                                  : "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            {objGamifycationStats.symbol.riskStatus}
                          </sup>
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <MDBox
                      display="flex"
                      mt={3}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#ffa600",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Pending -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.pendingAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.pendingAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#38c273",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Completed -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.completedAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.completedAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#e3352e",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Overdue -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.overdueAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.overdueAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            )}
          </MDBox>
        )}
      </>
      <>
        {objGamifycationStats.huntress != null && (
          <MDBox mb={2}>
            {/* charts */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Card>
                <MDBox display="flex" px={0} pt={0}>
                  <MDBox
                    mt={0}
                    pt={2}
                    pl={2}
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography fontWeight="bold" variant="h5" color="dark">
                      Security Awareness Training by Huntress{" "}
                    </MDTypography>
                    <img
                      alt="Symbol"
                      style={{ maxWidth: "80px", marginLeft: "1rem" }}
                      src={HuntressLogo}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="space-around">
                  <MDBox xs={12} sm={4} p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Assignments Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.huntress.pendingAssignments,
                              objGamifycationStats.huntress
                                .completedAssignments,
                              objGamifycationStats.huntress.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </MDBox>

                  <MDBox xs={12} sm={8} p={2}>
                    <MDTypography
                      fontWeight="bold"
                      variant="body2"
                      color="dark"
                      textAlign="center"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Phishing Campaign Stats
                    </MDTypography>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {/* Emails Sent */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Emails Sent
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.emailsSent}
                        </MDTypography>
                      </Grid>

                      {/* Phish Clicks */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Phish Clicks
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.phishClicks}
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Shared Data */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Compromise Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.compromiseRate}%
                          {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Rate */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.clickRate}%
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Factor */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Open Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.openRate}%
                          {/* <sup
                            style={{
                              color:
                                objGamifycationStats.huntress.riskStatus ===
                                "FAIR"
                                  ? "green"
                                  : "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            {objGamifycationStats.huntress.riskStatus}
                          </sup> */}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <MDBox
                      display="flex"
                      mt={3}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#ffa600",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Pending -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.pendingAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.pendingAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#38c273",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Completed -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.completedAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.completedAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#e3352e",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Overdue -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.overdueAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.overdueAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            )}
          </MDBox>
        )}
      </>
    </>
  );
}
