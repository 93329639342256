import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const getAllPendingReviewsForUser = createAsyncThunk(
  "company/getAllPendingReviewsForUser",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/notifications/pending-reviews-for-user`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchOnboardingProgressData = createAsyncThunk(
  "company/fetchOnboardingProgressData",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/notifications/onboarding-checklist`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const getAllOtherPendingReviewsForMSP = createAsyncThunk(
  "company/getAllOtherPendingReviewsForMSP",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/notifications/other-pending-reviews-for-msp`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssignedTaskListResponsibilitiesForUser = createAsyncThunk(
  "company/getPreviousSyncInformationForAssignedResponsibilities",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/notifications/assigned-task-list-responsibilities-for-user`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

