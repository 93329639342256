import lowPriorityLevel from "assets/images/priority_levels/low.svg";
import mediumPriorityLevel from "assets/images/priority_levels/medium.svg";
import highPriorityLevel from "assets/images/priority_levels/high.svg";
import highestPriorityLevel from "assets/images/priority_levels/highest.svg";

export const getPrioritySymbol = (priorityLevel) => {
  const priorityMap = {
    low: lowPriorityLevel,
    medium: mediumPriorityLevel,
    high: highPriorityLevel,
    highest: highestPriorityLevel,
  };
  return priorityMap[priorityLevel] ?? "?";
};
