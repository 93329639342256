import DefaultCell from "views/components/TableCells/DefaultCell";
import CompanyNameBtnTr from "./CompanyNameBtnTr";
const CustomerManagementDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "companyName",
      Cell: ({ value, column, row }) => (
        <CompanyNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "company email",
      accessor: "companyEmail",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "company phone",
      accessor: "companyPhone",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Plan",
      accessor: "plan",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "max no. of clients",
      accessor: "maxClients",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of clients",
      accessor: "numberOfClients",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of users",
      accessor: "numberOfUsers",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of policies",
      accessor: "numberOfPolicies",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "risk registry count",
      accessor: "riskRegistryCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "POAM count",
      accessor: "poamCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "decided action count",
      accessor: "completedPoamCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Most Used Template",
      accessor: "assessmentMostUsedTemplate",
      Cell: ({ value }) => <DefaultCell value={value?.name ?? "N/A"} />,
    },
    {
      Header: "Assessment Not Started",
      accessor: "assessmentNotStarted",
      Cell: ({ value }) => <DefaultCell value={value ?? "0"} />,
    },
    {
      Header: "Assessment In Progress",
      accessor: "assessmentInProgress",
      Cell: ({ value }) => <DefaultCell value={value ?? "0"} />,
    },
    {
      Header: "Assessment Completed",
      accessor: "assessmentCompleted",
      Cell: ({ value }) => <DefaultCell value={value ?? "0"} />,
    },
    {
      Header: "last login",
      accessor: "lastLogin",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date joined",
      accessor: "dateJoined",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default CustomerManagementDataTableData;
