// react-router-dom components

import Grid from "@mui/material/Grid";

import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";

const validateDecimal = (value) => {
  let error;
  const decimalRegex = /^\d+(\.\d+)?$/;
  if (!!value && !decimalRegex.test(value)) {
    error = "Please enter a valid decimal number.";
  }
  return error;
};

const EditCompanyUserFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    userCanEditHourlyRate,
  } = props;

  const getRoleDisplayValue = (value = "") => {
    return value === "company_user" ? "MSP User" : value;
  };
  return (
    <Fragment>
      <Grid container spacing={5} justifyContent="space-between" p={6}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Name"
                name="txtName"
                value={values.txtName}
                placeholder={"Name"}
                error={errors.txtName && touched.txtName}
                success={values.txtName.length > 0 && !errors.txtName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Phone Number"
                name="txtPhoneNumber"
                value={values.txtPhoneNumber}
                placeholder={"Phone Number"}
                error={errors.txtPhoneNumber && touched.txtPhoneNumber}
                success={
                  values.txtPhoneNumber.length > 0 && !errors.txtPhoneNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <FormField
                  type="text"
                  label="Email"
                  name="txtEmail"
                  value={values.txtEmail}
                  placeholder={"Email"}
                  error={errors.txtEmail && touched.txtEmail}
                  success={values.txtEmail.length > 0 && !errors.txtEmail}
                  disabled={true}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Last Name"
                name="txtLastName"
                value={values.txtLastName}
                placeholder={"Last Name"}
                error={errors.txtLastName && touched.txtLastName}
                success={values.txtLastName?.length > 0 && !errors.txtLastName}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormField
                type="text"
                label="Role"
                name="txtRole"
                value={values.txtRole}
                placeholder={"Role"}
                error={errors.txtRole && touched.txtRole}
                success={values.txtRole.length > 0 && !errors.txtRole}
                disabled={true}
              /> */}
              <Autocomplete
                disableClearable
                value={values.txtRole}
                disabled={values.txtRole == "client_user"}
                options={["admin", "company_user"]}
                getOptionLabel={(option) => getRoleDisplayValue(option)}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Role"
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null) {
                    setFieldValue("txtRole", "");
                  } else {
                    setFieldValue("txtRole", value);
                  }
                }}
              />
            </Grid>
            {userCanEditHourlyRate && (
              <Grid item xs={12}>
                <MDBox>
                  <FormField
                    type="text"
                    label="Hourly Rate"
                    name="txtHourlyRate"
                    value={values.txtHourlyRate}
                    placeholder={"Hourly Rate"}
                    error={errors.txtHourlyRate && touched.txtHourlyRate}
                    success={
                      values.txtHourlyRate?.length > 0 && !errors.txtHourlyRate
                    }
                    validate={validateDecimal}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EditCompanyUserFormikWrapper;
