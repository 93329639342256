import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewTemplateBulk } from "features/company/assessmentsActions";
import { get } from "features/assessmentConfig/assessmentConfigActions";
import Papa from "papaparse";
import stringSimilarity from "string-similarity";
import ForwardIcon from "@mui/icons-material/Forward";
import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Checkbox, Grid } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

const objInitialValues = {
  txtName: "",
  txtDescription: "",
  drpTemplateType: { id: "", name: "" },
  subscription_category: "lite",
};
let objInitialMappingValues = {
  "assessment_name(s)": "",
  control_id: "",
  control_objective_id: "",
  control_objective_item: "",
  category: "",
  subcategory: "",
  item: "",
  scoring_instructions: "",
  explanation: "",
  not_applicable_response: "",
  unknown_response: "",
  at_risk_response: "",
  needs_attention_response: "",
  acceptable_risk_response: "",
  satisfactory_response: "",
  sprs_score: "",
};
const ImportTemplateCSVFormJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("The Name is required."),
  drpTemplateType: Yup.object().shape({
    id: Yup.string().required("The Template Type is required."),
    name: Yup.string().required("The Template Type is required."),
  }),
  subscription_category: Yup.string().required(
    "The Subscription Category is required."
  ),
});
const MapCSVFormJWTSchema = Yup.object().shape({
  "assessment_name(s)": Yup.string().required("This Field is required."),
  control_id: Yup.string().required("This Field is required."),
  category: Yup.string().required("This Field is required."),
  item: Yup.string().required("This Field is required."),
  scoring_instructions: Yup.string().required("This Field is required."),
  explanation: Yup.string().required("This Field is required."),
  // not_applicable_response: Yup.string().required("This Field is required."),
  // unknown_response: Yup.string().required("This Field is required."),
  // at_risk_response: Yup.string().required("This Field is required."),
  // needs_attention_response: Yup.string().required("This Field is required."),
  // acceptable_risk_response: Yup.string().required("This Field is required."),
  // satisfactory_response: Yup.string().required("This Field is required."),
});
const subscription_categories = ["lite", "plus"];

function ImportTemplateCSVDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [importArray, setImportArray] = useState([]);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [showMappingDialog, setShowMappingDialog] = useState(false);
  const [columnDefaults, setColumnDefaults] = useState({});
  const [step, setStep] = useState(1);
  const [csvColumns, setCSVColumns] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();
  const mapFormRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    dispatch(get({ _per_page: 100, _page: 0, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        setTemplateTypes(
          originalPromiseResult.data.assessment_types.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  }, []);

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtName,
      description: values.txtDescription,
      template_type: values.drpTemplateType.id,
      subscription_category: values.subscription_category,
      import_array: importArray,
      global: true,
    };
    if (importArray.length === 0) {
      showMsg("error", "Empty file. Please select a CSV file to import.");
      return;
    }
    setIsLoading(true);
    dispatch(createNewTemplateBulk({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data.id);
          onCloseMapping();
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
        // console.log(
        //   "rejectedValueOrSerializedError",
        //   rejectedValueOrSerializedError
        // );
      });
  };

  function handleOnFileChange(e) {
    parseCSV(e.target.files[0]);
  }

  function onCloseMapping() {
    setColumnDefaults({
      "assessment_name(s)": false,
      control_id: false,
      control_objective_id: false,
      control_objective_item: false,
      category: false,
      subcategory: false,
      item: false,
      scoring_instructions: false,
      explanation: false,
      not_applicable_response: false,
      unknown_response: false,
      at_risk_response: false,
      needs_attention_response: false,
      acceptable_risk_response: false,
      satisfactory_response: false,
      sprs_score: false,
    });
    setStep(1);
    setShowMappingDialog(false);
    setCSVColumns([]);
    onClose();
  }

  function parseCSV(file) {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setImportArray(
            results.data.filter(
              (item) =>
                !Object.values(item).every((x) => x === null || x === "")
            )
          );
          if (
            !results.data[0]["assessment_name(s)"] ||
            !results.data[0]["control_id"] ||
            !results.data[0]["control_objective_id"] ||
            !results.data[0]["control_objective_item"] ||
            !results.data[0]["category"] ||
            !results.data[0]["subcategory"] ||
            !results.data[0]["item"] ||
            !results.data[0]["scoring_instructions"] ||
            !results.data[0]["explanation"] ||
            !results.data[0]["not_applicable_response"] ||
            !results.data[0]["unknown_response"] ||
            !results.data[0]["at_risk_response"] ||
            !results.data[0]["needs_attention_response"] ||
            !results.data[0]["acceptable_risk_response"] ||
            !results.data[0]["satisfactory_response"] ||
            !results.data[0]["sprs_Score"]
          ) {
            let columnNames = Object.keys(results.data[0]);
            setCSVColumns(columnNames);
            let correctNames = [
              "assessment_name(s)",
              "control_id",
              "control_objective_id",
              "control_objective_item",
              "category",
              "subcategory",
              "item",
              "scoring_instructions",
              "explanation",
              "not_applicable_response",
              "unknown_response",
              "at_risk_response",
              "needs_attention_response",
              "acceptable_risk_response",
              "satisfactory_response",
              "sprs_score",
            ];

            const fuzzyObject = correctNames.reduce((acc, name) => {
              const match = stringSimilarity.findBestMatch(name, columnNames);
              const bestMatch = match.bestMatch.target;
              const matchScore = match.bestMatch.rating;
              // acc[name] = match.bestMatch.target;
              // Skip if the column name has already been used
              // if (!Object.values(acc).includes(match.bestMatch.target)) {
              //   acc[name] = match.bestMatch.target;
              // }
              if (
                !Object.values(acc).includes(bestMatch) &&
                matchScore >= 0.5
              ) {
                acc[name] = bestMatch;
              } else {
                acc[name] = "";
              }
              return acc;
            }, {});
            // console.log(fuzzyObject);
            objInitialMappingValues = fuzzyObject;
            setShowMappingDialog(true);
            // show new modal add new state
            showMsg(
              "error",
              "Invalid column headers. Manually map the columns to import."
            );
            formRef?.current?.setFieldValue("txtName", "");
            return;
          } else {
            formRef?.current?.setFieldValue(
              "txtName",
              results.data[0]["assessment_name(s)"]
            );
          }
        },
        error: function (err, file, inputElem, reason) {
          //   console.log("Error:", err, reason);
        },
      });
    }
  }

  const mapCSV = async (values) => {
    let corrections = {
      acceptable_risk_response: values.acceptable_risk_response,
      "assessment_name(s)": values["assessment_name(s)"],
      at_risk_response: values.at_risk_response,
      control_id: values.control_id,
      control_objective_id: values.control_objective_id,
      control_objective_item: values.control_objective_item,
      category: values.category,
      subcategory: values.subcategory,
      explanation: values.explanation,
      item: values.item,
      needs_attention_response: values.needs_attention_response,
      not_applicable_response: values.not_applicable_response,
      satisfactory_response: values.satisfactory_response,
      scoring_instructions: values.scoring_instructions,
      unknown_response: values.unknown_response,
      sprs_score: values.sprs_score,
    };

    let data = importArray;
    let newData = data.map((obj) => {
      let newObj = {};
      Object.keys(obj).forEach((key) => {
        Object.keys(corrections).forEach((correctKey) => {
          // console.log(columnDefaults[correctKey])
          if (columnDefaults[correctKey]) {
            newObj[correctKey] = values[correctKey];
            // delete obj[key];
          } else {
            if (key === corrections[correctKey]) {
              newObj[correctKey] = obj[key];
              // delete obj[key];
            } else {
              newObj[key] = obj[key];
            }
          }
        });
      });
      return newObj;
    });
    setImportArray(newData);
    setStep(2);
  };

  const toggleDefault = (column) => {
    mapFormRef?.current?.setFieldValue(column, "");
    if (columnDefaults[column]) {
      setColumnDefaults((prevState) => ({
        ...prevState,
        [column]: false,
      }));
    } else {
      setColumnDefaults((prevState) => ({
        ...prevState,
        [column]: true,
      }));
    }
  };

  return (
    <>
      {!showMappingDialog ? (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>Import Template As</DialogTitle>
          <Formik
            initialValues={objInitialValues}
            validationSchema={ImportTemplateCSVFormJWTSchema}
            onSubmit={onSubmitForm}
            innerRef={formRef}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id="AddNewTemplateForm" autoComplete="off">
                <DialogContent>
                  <MDBox mb={0} mt={0}>
                    <Field
                      type="text"
                      label="Name"
                      name="txtName"
                      value={values.txtName}
                      placeholder={"Template name"}
                      error={errors.txtName && touched.txtName}
                      success={values.txtName.length > 0 && !errors.txtName}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                      autoFocus
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="txtName" />
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox mb={0} mt={0}>
                    <Field
                      type="text"
                      label="Description"
                      name="txtDescription"
                      value={values.txtDescription}
                      placeholder={"Template description"}
                      error={errors.txtDescription && touched.txtDescription}
                      success={
                        values.txtDescription.length > 0 &&
                        !errors.txtDescription
                      }
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="txtDescription" />
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox mb={0} mt={0}>
                    <Autocomplete
                      name="drpTemplateType.name"
                      disableClearable
                      value={values.drpTemplateType}
                      options={templateTypes}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Template Type"
                          fullWidth
                        />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setFieldValue("drpTemplateType", "");
                        } else {
                          setFieldValue("drpTemplateType", value);
                        }
                      }}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="drpTemplateType.name" />
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox mb={0} mt={0}>
                    <Autocomplete
                      disableClearable
                      name="subscription_category"
                      options={subscription_categories}
                      getOptionLabel={(option) =>
                        option.charAt(0).toUpperCase() + option.slice(1)
                      }
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Subscription Category"
                          name="subscription_category"
                          fullWidth
                        />
                      )}
                      value={values.subscription_category}
                      onChange={(event, newValue) => {
                        setFieldValue("subscription_category", newValue);
                      }}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name="drpTemplateType" />
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox mb={0} mt={0}>
                    <MDInput
                      type={"file"}
                      onChange={(e) => handleOnFileChange(e)}
                      accept={".csv"}
                      label="CSV File"
                      variant={"standard"}
                      autoFocus
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  {isLoading === false ? (
                    <>
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 12px" }}
                      >
                        Import
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                    </>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      ) : (
        <>
          {step == 1 ? (
            <Dialog
              open={showMappingDialog}
              onClose={onCloseMapping}
              fullWidth={true}
            >
              <DialogTitle sx={{ pb: 0 }}>Map Template CSV Columns</DialogTitle>
              <Formik
                initialValues={objInitialMappingValues}
                validationSchema={MapCSVFormJWTSchema}
                onSubmit={mapCSV}
                innerRef={mapFormRef}
                enableReinitialize
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="ImportCSVForm" autoComplete="off">
                    <DialogContent>
                      {/* <Grid
                        container
                        spacing={2}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Grid item xs={5}>
                          {columnDefaults["assessment_name(s)"] ? (
                            <MDBox mb={0} mt={1}>
                              <Field
                                type="text"
                                // label="Type default value"
                                name="assessment_name(s)"
                                value={values["assessment_name(s)"]}
                                placeholder="Type default value"
                                error={
                                  errors["assessment_name(s)"] &&
                                  touched["assessment_name(s)"]
                                }
                                success={
                                  values["assessment_name(s)"].length > 0 &&
                                  !errors["assessment_name(s)"]
                                }
                                as={MDInput}
                                variant="standard"
                                fullWidth
                                autoFocus
                              />
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  <ErrorMessage name="assessment_name(s)" />
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          ) : (
                            <MDBox mb={0} mt={1}>
                              <Autocomplete
                                name="assessment_name(s)"
                                disableClearable
                                value={values["assessment_name(s)"]}
                                options={csvColumns}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <MDInput
                                    {...params}
                                    variant="standard"
                                    // label="assessment_name(s)"
                                    fullWidth
                                  />
                                )}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    setFieldValue("assessment_name(s)", "");
                                  } else {
                                    setFieldValue("assessment_name(s)", value);
                                  }
                                }}
                              />
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  <ErrorMessage name="assessment_name(s)" />
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          )}
                        </Grid>
                        <Grid item xs={7}>
                          <MDBox
                            mb={0}
                            mt={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <MDBox display="flex" alignItems="center">
                              <ForwardIcon />
                              <MDTypography
                                component="div"
                                variant="overline"
                                // color="error"
                                fontWeight="regular"
                                ml={1}
                                mr={2}
                              >
                                assessment_name(s)
                              </MDTypography>
                            </MDBox>
                            <MDBox display="flex" alignItems="center">
                              <Checkbox
                                id={"assessment_name(s)"}
                                sx={{
                                  transform: "scale(0.8)",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                  },
                                }}
                                size={"small"}
                                checked={columnDefaults["assessment_name(s)"]}
                                onChange={(event) => {
                                  toggleDefault("assessment_name(s)");
                                }}
                              />
                              <label htmlFor={"assessment_name(s)"}>
                                <MDTypography
                                  component="div"
                                  variant="overline"
                                  // color="error"
                                  fontWeight="regular"
                                  mr={1}
                                >
                                  default value{" "}
                                </MDTypography>
                              </label>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid> */}

                      {Object.keys(objInitialMappingValues).map((column) => (
                        <Grid
                          container
                          spacing={2}
                          display={"flex"}
                          alignItems={"flex-end"}
                        >
                          <Grid item xs={5}>
                            <MDBox
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <MDTypography
                                component="div"
                                variant="overline"
                                // color="error"
                                fontWeight="regular"
                                // ml={1}
                                // mr={2}
                              >
                                {column}
                              </MDTypography>
                              <ForwardIcon sx={{ transform: "scaleX(-1)" }} />
                            </MDBox>
                          </Grid>
                          <Grid item xs={7}>
                            <MDBox
                              mb={0}
                              mt={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              {columnDefaults[column] ? (
                                <MDBox mb={0} mt={1} sx={{ flexGrow: 1 }}>
                                  <MDBox display="flex" alignItems="center">
                                    <Field
                                      type={
                                        column == "sprs_score"
                                          ? "number"
                                          : "text"
                                      }
                                      // label="Type default value"
                                      name={column}
                                      value={values[column]}
                                      placeholder="Type default value"
                                      error={errors[column] && touched[column]}
                                      success={
                                        values[column].length > 0 &&
                                        !errors[column]
                                      }
                                      as={MDInput}
                                      variant="standard"
                                      fullWidth
                                      autoFocus
                                    />
                                    {column != "assessment_name(s)" &&
                                    column != "control_id" &&
                                    column != "category" &&
                                    column != "item" &&
                                    column != "scoring_instructions" &&
                                    column != "explanation" ? (
                                      <DisabledByDefaultIcon
                                        color="error"
                                        cursor="pointer"
                                        onClick={() =>
                                          setFieldValue(column, "")
                                        }
                                      />
                                    ) : (
                                      <MDTypography color="error" variant="">
                                        *
                                      </MDTypography>
                                    )}
                                  </MDBox>
                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      <ErrorMessage name={column} />
                                    </MDTypography>
                                  </MDBox>
                                </MDBox>
                              ) : (
                                <MDBox mb={0} mt={1} sx={{ flexGrow: 1 }}>
                                  <MDBox display="flex" alignItems="center">
                                    <Autocomplete
                                      name={column}
                                      disableClearable
                                      value={values[column]}
                                      options={csvColumns}
                                      sx={{ flexGrow: 1 }}
                                      getOptionLabel={(option) => option}
                                      renderInput={(params) => (
                                        <MDInput
                                          {...params}
                                          variant="standard"
                                          // label="category"
                                          fullWidth
                                        />
                                      )}
                                      onChange={(event, value) => {
                                        if (value === null) {
                                          setFieldValue(column, "");
                                        } else {
                                          setFieldValue(column, value);
                                        }
                                      }}
                                    />
                                    {column != "assessment_name(s)" &&
                                    column != "control_id" &&
                                    column != "category" &&
                                    column != "item" &&
                                    column != "scoring_instructions" &&
                                    column != "explanation" ? (
                                      <DisabledByDefaultIcon
                                        color="error"
                                        cursor="pointer"
                                        onClick={() =>
                                          setFieldValue(column, "")
                                        }
                                      />
                                    ) : (
                                      <MDTypography color="error" variant="">
                                        *
                                      </MDTypography>
                                    )}
                                  </MDBox>

                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      <ErrorMessage name={column} />
                                    </MDTypography>
                                  </MDBox>
                                </MDBox>
                              )}

                              <MDBox display="flex" alignItems="center">
                                <Checkbox
                                  id={column}
                                  sx={{
                                    transform: "scale(0.8)",
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 20,
                                    },
                                  }}
                                  size={"small"}
                                  checked={columnDefaults[column]}
                                  onChange={(event) => {
                                    toggleDefault(column);
                                  }}
                                />
                                <label htmlFor={column}>
                                  <MDTypography
                                    component="div"
                                    variant="overline"
                                    // color="error"
                                    fontWeight="regular"
                                    // mr={}
                                  >
                                    default value{" "}
                                  </MDTypography>
                                </label>
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      ))}
                    </DialogContent>
                    <DialogActions>
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 12px" }}
                      >
                        Map
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onCloseMapping}
                      >
                        Cancel
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Dialog>
          ) : (
            <Dialog open={open} onClose={onCloseMapping} fullWidth={true}>
              <DialogTitle sx={{ pb: 0 }}>Import Template As</DialogTitle>
              <Formik
                initialValues={{
                  ...objInitialValues,
                  txtName: importArray[0]["assessment_name(s)"],
                }}
                validationSchema={ImportTemplateCSVFormJWTSchema}
                onSubmit={onSubmitForm}
                innerRef={formRef}
                enableReinitialize
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="AddNewTemplateForm" autoComplete="off">
                    <DialogContent>
                      <MDBox mb={0} mt={0}>
                        <Field
                          type="text"
                          label="Name"
                          name="txtName"
                          value={values.txtName}
                          placeholder={"Template name"}
                          error={errors.txtName && touched.txtName}
                          // success={values.txtName.length > 0 && !errors.txtName}
                          as={MDInput}
                          variant="standard"
                          fullWidth
                          autoFocus
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="txtName" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox mb={0} mt={0}>
                        <Field
                          type="text"
                          label="Description"
                          name="txtDescription"
                          value={values.txtDescription}
                          placeholder={"Template description"}
                          error={
                            errors.txtDescription && touched.txtDescription
                          }
                          // success={
                          //   values.txtDescription.length > 0 &&
                          //   !errors.txtDescription
                          // }
                          as={MDInput}
                          variant="standard"
                          fullWidth
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="txtDescription" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox mb={0} mt={0}>
                        <Autocomplete
                          name="drpTemplateType"
                          disableClearable
                          value={values.drpTemplateType}
                          options={templateTypes}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="Template Type"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            if (value === null) {
                              setFieldValue("drpTemplateType", "");
                            } else {
                              setFieldValue("drpTemplateType", value);
                            }
                          }}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="drpTemplateType" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox mb={0} mt={0}>
                        <Autocomplete
                          disableClearable
                          name="subscription_category"
                          options={subscription_categories}
                          getOptionLabel={(option) =>
                            option.charAt(0).toUpperCase() + option.slice(1)
                          }
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="Subscription Category"
                              name="subscription_category"
                              fullWidth
                            />
                          )}
                          value={values.subscription_category}
                          onChange={(event, newValue) => {
                            setFieldValue("subscription_category", newValue);
                          }}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="drpTemplateType" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </DialogContent>
                    <DialogActions>
                      {isLoading === false ? (
                        <>
                          <MDButton
                            type="submit"
                            // variant="gradient"
                            color="success"
                            sx={{ padding: "12px 12px" }}
                          >
                            Import
                          </MDButton>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="dark"
                            sx={{ padding: "12px 12px" }}
                            onClick={onCloseMapping}
                          >
                            Cancel
                          </MDButton>
                        </>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Dialog>
          )}
        </>
      )}
    </>
  );
}
export default ImportTemplateCSVDialog;
