import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ClientContactActionBtnTr = (props) => {
  const {
    row,
    editTheClientContact,
    isDeletingClientContact,
    deleteTheClientContact,
    changeUserStatus,
    objSelectedClientContact,
    addToAudienceContact,
    resendInvite,
    isInvitingClientContact,
    isClientUser,
    showInviteClientDialog,
  } = props;
  const confirm = useConfirm();
  const btnOnClickAddToAudienceContact = (data) => {
    addToAudienceContact(data);
  };
  const btnOnClickEditClientContact = (data) => {
    editTheClientContact(data);
  };
  const btnOnClickResendInvite = (data) => {
    confirm({
      description: "Resend invite to this contact?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        resendInvite(data);
      })
      .catch(() => {
        /* ... */
      });
  };
  const btnOnClickDeletClientContact = (data) => {
    confirm({
      description: "Are you sure, You want to delete this contact?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteTheClientContact(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnOnClickUpdateStatus = (data, status) => {
    confirm({
      description: `Are you sure, You want to ${status == 'Active' ? 'activate' : 'deactivate'} this contact?`,
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        changeUserStatus(data, status);
      })
      .catch(() => {
        /* ... */
      });
  };

  let inviteActionContent = "";

  if (
    typeof row.original !== "undefined" &&
    typeof row.original.element !== "undefined"
  ) {
    // Already a system user?
    if (
      typeof row.original.element.user !== "undefined" &&
      row.original.element.user !== null
    ) {
      inviteActionContent = (
        <MDButton disabled>
          <MDBox color="dark" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>check</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="success"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              User
            </MDTypography>
          </MDBox>
        </MDButton>
      );
    } else if (
      typeof row.original.element.invites !== "undefined" &&
      Array.isArray(row.original.element.invites) &&
      row.original.element.invites.length > 0
    ) {
      inviteActionContent = (
        <MDButton
          disabled={isInvitingClientContact ? true : false}
          onClick={() => btnOnClickResendInvite(row.original)}
          sx={{ paddingLeft: "0" }}
        >
          <MDBox color="dark" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>timer</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="text.dark"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Resend Invite
            </MDTypography>
          </MDBox>
        </MDButton>
      );
    } else {
      inviteActionContent = (
        <MDButton
          disabled={isInvitingClientContact ? true : false}
          onClick={() => showInviteClientDialog(row.original)}
        >
          <MDBox color="dark" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>send</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="text.dark"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Invite
            </MDTypography>
          </MDBox>
        </MDButton>
      );
    }
  }
  return (
    <Fragment>
      {isClientUser ? (
        ""
      ) : (
        <Fragment>
          {" "}
          <MDButton
            onClick={() => btnOnClickEditClientContact(row.original)}
            sx={{ margin: "0 5px;" }}
          >
            <MDBox color="dark" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              <MDTypography
                variant="p"
                fontWeight="medium"
                color="text.dark"
                sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
              >
                Edit
              </MDTypography>
            </MDBox>
          </MDButton>
          {row.original.status != null ? (
            <>
              <MDButton
                onClick={() =>
                  btnOnClickUpdateStatus(
                    row.original,
                    `${row.original.status == "Active" ? "Inactive" : "Active"}`
                  )
                }
                // disabled={row.original.status === "Invited"}
                // sx={{
                //   display: `${objUser.user.role.name != "admin" ? "none" : ""}`,
                //   paddingLeft: 0,
                // }}
              >
                {isDeletingClientContact &&
                row.original.element.id ===
                  objSelectedClientContact.element.id ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="sm"
                  />
                ) : (
                  <MDBox
                    color={
                      row.original.status == "Active" ? "error" : "success"
                    }
                    display="flex"
                    alignItems="center"
                  >
                    {row.original.status == "Active" ? (
                      <NoAccountsIcon />
                    ) : (
                      <AccountCircleIcon />
                    )}
                    <MDTypography
                      variant="caption"
                      fontWeight="medium"
                      color={
                        row.original.status == "Active" ? "error" : "success"
                      }
                      sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                    >
                      {row.original.status == "Active"
                        ? "Deactivate"
                        : "Activate"}
                    </MDTypography>
                  </MDBox>
                )}
              </MDButton>
            </>
          ) : (
            <>
              {isDeletingClientContact &&
              Object.keys(objSelectedClientContact).length > 0 &&
              row.original.element.id ===
                objSelectedClientContact.element.id ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <MDButton
                  onClick={() => btnOnClickDeletClientContact(row.original)}
                  variant="gradient"
                  sx={{ margin: "0 5px;" }}
                >
                  <MDBox color="error" display="flex" alignItems="center">
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                    <MDTypography
                      variant="p"
                      fontWeight="medium"
                      color="error"
                      sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                    >
                      Delete
                    </MDTypography>
                  </MDBox>
                </MDButton>
              )}{" "}
            </>
          )}
        </Fragment>
      )}

      <MDButton
        onClick={() => btnOnClickAddToAudienceContact(row.original)}
        sx={{ margin: "0 5px;" }}
      >
        <MDBox color="dark" display="flex" alignItems="center">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0 }}
          >
            Add to audience
          </MDTypography>
        </MDBox>
      </MDButton>
      {inviteActionContent}
    </Fragment>
  );
};

export default ClientContactActionBtnTr;
