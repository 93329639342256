import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAssessments,
  generateAuditorReport,
  getInvitedAuditors,
} from "features/auditorReports/auditorReportActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AssessmentEventsManagementTable from "./AssessmentEventsTable/ManagementTable";
import AssessmentEventVersionsManagementTable from "./AllAssessmentEventVersionsTable/ManagementTable";
import dataTableData from "./AssessmentEventsTable/DataTableData";
import dataTableDataVersions from "./AllAssessmentEventVersionsTable/DataTableData";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import MDInput from "components/MDInput";

const Index = (props) => {
  const navigate = useNavigate();
  const { objUser } = props;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [trackedAssessments, setTrackedAssessments] = useState([]);
  const [allTrackedAssessmentVersions, setAllTrackedAssessmentVersions] =
    useState([]);
  const [
    allTrackedAssessmentVersionsOriginal,
    setAllTrackedAssessmentVersionsOriginal,
  ] = useState([]);
  const [overviewOscalSections, setOverviewOscalSections] = useState([]);
  const [objPoliciesTableData, setObjPoliciesTableData] =
    useState(dataTableData);
  const [objAssessmentVersionsTableData, setObjAssessmentVersionsTableData] =
    useState(dataTableDataVersions);
  const [totalCount, setTotalCount] = useState(0);
  const [allVersionstotalCount, setAllVersionsTotalCount] = useState(0);
  const [pagePolicies, setPagePolicies] = useState(1);
  const [sections, setSections] = useState([]);
  const [sspName, setSspName] = useState("");
  const [introductionDescription, setIntroductionDescription] = useState(null);
  const [
    introductionDescriptionUnchanged,
    setIntroductionDescriptionUnchanged,
  ] = useState(null);
  const [clientName, setClientName] = useState("");
  const [open, setOpen] = useState(false);
  const [isGeneratingSsp, setIsGeneratingSsp] = useState(false);

  const [step, setStep] = useState(1);

  const [auditors, setAuditors] = useState([]);
  const [selectedAuditor, setSelectedAuditor] = useState(null);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchAssessments();
    fetchInvitedAuditors();
  }, []);

  const fetchAssessments = () => {
    setIsLoading(true);
    dispatch(getAssessments({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.assessments;
        const allAssessmentEventVersions =
          response.data.all_assessment_event_versions;
        const overviewOscalSections = response.data.overview_sections;
        const modifiedSections = overviewOscalSections.map((section) => {
          return { ...section, section_content: null };
        });

        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.version = element.version ? element.version + ".0" : "";
          objTr.name = element.assessment_template.name;
          objTr.template_id = element.assessment_template.id;
          objTr.created_by = element.created_by.name;
          objTr.created_by_last_name = element.created_by.last_name;
          objTr.status = capitalizeFirstLetter(
            element.status.replace("_", " ")
          );
          objTr.completed_on = standardDateFormat(element.end_date);
          objTr.last_modified = standardDateFormat(element.updated_at);
          objTr.selected = false;
          objTr.assigned_to_client_questions_count = element.questions_count;
          objTr.order = index + 1;
          objTr.element = element;
          objRows.push(objTr);

          setTrackedAssessments(objRows);
          setTotalCount(response.data.total);
          setObjPoliciesTableData({
            ...dataTableData,
            rows: objRows,
          });
        }

        loadAllAssessmentVersions(allAssessmentEventVersions);

        setClientName(response.data.client_name);
        setOverviewOscalSections(modifiedSections);
        setIntroductionDescription(response.data.introduction_description);
        setIntroductionDescriptionUnchanged(
          response.data.introduction_description
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const fetchInvitedAuditors = () => {
    dispatch(getInvitedAuditors({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        setAuditors(response.data.auditors);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to retrieve the list of your auditors, please try again."
        );
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const loadAllAssessmentVersions = (
    assessmentEventVersions,
    reload = false
  ) => {
    const objRows = [];

    for (let index = 0; index < assessmentEventVersions.length; index++) {
      const element = reload
        ? assessmentEventVersions[index].element
        : assessmentEventVersions[index];

      const objTr = {};
      objTr.id = element.id;
      objTr.version = element.version ? element.version + ".0" : "";
      objTr.name = element.assessment_template.name;
      objTr.template_id = element.assessment_template.id;
      objTr.created_by = element.created_by.name;
      objTr.created_by_last_name = element.created_by.last_name;
      objTr.status = capitalizeFirstLetter(element.status.replace("_", " "));
      objTr.completed_on = standardDateFormat(element.end_date);
      objTr.last_modified = standardDateFormat(element.updated_at);
      objTr.selected = false;
      objTr.assigned_to_client_questions_count = element.questions_count;
      objTr.order = index + 1;
      objTr.element = element;
      objRows.push(objTr);

      setAllTrackedAssessmentVersions(objRows);
      setAllVersionsTotalCount(assessmentEventVersions.length);
      setObjAssessmentVersionsTableData({
        ...dataTableDataVersions,
        rows: objRows,
      });
    }
  };

  const handleSetStep2 = () => {
    const currentAsmnts = [...trackedAssessments];
    const currentAllVersions = [...allTrackedAssessmentVersions];
    const selectedAsmnts = currentAsmnts.filter(
      (item) => item.selected === true
    );
    setAllTrackedAssessmentVersionsOriginal(currentAllVersions);

    if (selectedAsmnts.length > 0) {
      const filteredVersionsForTemplate = currentAllVersions.filter(
        (item) => item.template_id === selectedAsmnts[0].template_id
      );

      if (filteredVersionsForTemplate.length === 1) {
        //if there is only 1 version
        //manually select single version in all tracked assessment versions
        preProcessSelectedAssessment(selectedAsmnts);
      } else {
        loadAllAssessmentVersions(filteredVersionsForTemplate, true);
        setStep(2);
      }
    } else {
      showMsg("info", "Please select assessment to include in CCA");
    }
  };

  const preProcessSelectedAssessment = (selectedAsmnts) => {
    const currentIntroDesc = introductionDescription;

    let newIntroDescReplacedTitle = currentIntroDesc.replace(
      new RegExp("{title of assessment}", "g"),
      selectedAsmnts[0]?.name
    );

    let newIntroDescReplacedCompletionDate = newIntroDescReplacedTitle.replace(
      new RegExp("{assessment completion_date}", "g"),
      selectedAsmnts[0]?.status === "Completed"
        ? selectedAsmnts[0]?.completed_on
        : selectedAsmnts[0]?.last_modified
    );

    let newIntroDescReplacePersonName =
      newIntroDescReplacedCompletionDate.replace(
        new RegExp("{assessment_person_name}", "g"),
        `${selectedAsmnts[0]?.created_by} ${
          selectedAsmnts[0]?.created_by_last_name
            ? selectedAsmnts[0]?.created_by_last_name
            : ""
        }`
      );

    let finalIntroDesc = newIntroDescReplacePersonName;

    if (selectedAsmnts[0]?.assigned_to_client_questions_count > 0) {
      finalIntroDesc = newIntroDescReplacePersonName.replace(
        new RegExp("{company}", "g"),
        clientName
      );
    } else {
      finalIntroDesc = newIntroDescReplacePersonName.replace(
        new RegExp("in conjunction with {company}", "g"),
        ""
      );
    }

    setSections(selectedAsmnts);
    setIntroductionDescription(finalIntroDesc);
    setStep(3);
  };

  const handleSetStep3 = () => {
    const currentAsmnts = [...allTrackedAssessmentVersions];
    const selectedAsmnts = currentAsmnts.filter(
      (item) => item.selected === true
    );
    // const currentIntroDesc = introductionDescription;
    if (selectedAsmnts.length > 0) {
      preProcessSelectedAssessment(selectedAsmnts);
    } else {
      showMsg("info", "Please select assessment to include in CCA");
    }
  };

  const handleSetStep4 = () => {
    if (sspName == "" || sspName == null) {
      showMsg("info", "Please provide a name for the auditor report");
    } else {
      setStep(4);
    }
  };

  const btnOnClickgenerateSsp = () => {
    if (selectedAuditor == null || selectedAuditor == "") {
      showMsg("info", "Please choose an auditor");
    } else {
      setIsGeneratingSsp(true);
      dispatch(
        generateAuditorReport({
          objUser,
          sections,
          sspName,
          selectedAuditor,
          introductionDescription,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoading(false);
          const response = originalPromiseResult;
          const auditorReportId = response.data.auditorReport.id;
          routeDictionary[auditorReportId] = response.data.auditorReport.name;
          navigate("/auditor-reports/" + auditorReportId, {
            state: { routeDictionary },
          });
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsGeneratingSsp(false);
          // const objErrorRsp = rejectedValueOrSerializedError.response;

          if (rejectedValueOrSerializedError.response.data.errors) {
            rejectedValueOrSerializedError.response.data.errors.forEach(
              (error) => {
                showMsg("error", error);
              }
            );
          } else if (rejectedValueOrSerializedError.response.data.message) {
            showMsg(
              "error",
              rejectedValueOrSerializedError.response.data.message
            );
          } else {
            showMsg("error", "Something went wrong, please try again.");
          }
          console.log(rejectedValueOrSerializedError);
        });
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />

        {/* Generated CCA Dialog */}
        <Dialog open={open} onClose={onClose} fullWidth={true}>
          <DialogTitle sx={{ pb: 0 }}>CCA Name</DialogTitle>

          <DialogContent>
            <MDBox mt={2}>
              <TextField
                label="Enter CCA Name"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                }}
                onChange={(e) => setSspName(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
                InputProps={{
                  sx: {
                    fontWeight: 700,
                  },
                }}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            {isGeneratingSsp === false ? (
              <>
                <MDButton
                  type="submit"
                  onClick={() => btnOnClickgenerateSsp()}
                  color="success"
                  sx={{ padding: "12px 12px" }}
                >
                  Generate
                </MDButton>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="dark"
                  sx={{ padding: "12px 12px" }}
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
              </>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </DialogActions>
        </Dialog>

        {/* selecting assessment event */}
        <Grid
          container
          width="100%"
          display={step === 1 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalCount == 0 ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You don't have any completed assessment events yet
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox
                        mb={2}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          paddingLeft: "2rem",
                          paddingTop: "2rem",
                          width: "100%",
                        }}
                      >
                        <MDTypography
                          // sx={{ backgroundColor: "red" }}
                          variant="p"
                          fontWeight="bold"
                          color="primary"
                        >
                          Step 1: Select assessment event for Auditor Report
                        </MDTypography>
                      </MDBox>
                      <AssessmentEventsManagementTable
                        table={objPoliciesTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalCount}
                        // loadMoreRMF={loadMoreRMF}
                        // perPage={perPageRMF}
                        // setPerPage={setPerPageRMF}
                        _page={pagePolicies}
                        setPage={setPagePolicies}
                        // setObjRMFTableData={setObjPoliciesTableData}
                        setTrackedAssessments={setTrackedAssessments}
                        trackedAssessments={trackedAssessments}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>

        {/* choosing event version */}
        <Grid
          container
          width="100%"
          display={step === 2 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {allVersionstotalCount == 0 ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You don't have any versions for this event
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox
                        mb={2}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          paddingLeft: "2rem",
                          paddingTop: "2rem",
                          width: "100%",
                        }}
                      >
                        <MDTypography
                          // sx={{ backgroundColor: "red" }}
                          variant="p"
                          fontWeight="bold"
                          color="primary"
                        >
                          Step 2: Choose Assessment Version
                        </MDTypography>
                      </MDBox>
                      <AssessmentEventVersionsManagementTable
                        table={objAssessmentVersionsTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={allVersionstotalCount}
                        // loadMoreRMF={loadMoreRMF}
                        // perPage={perPageRMF}
                        // setPerPage={setPerPageRMF}
                        _page={pagePolicies}
                        setPage={setPagePolicies}
                        // setObjRMFTableData={setObjPoliciesTableData}
                        setAllTrackedAssessmentVersions={
                          setAllTrackedAssessmentVersions
                        }
                        allTrackedAssessmentVersions={
                          allTrackedAssessmentVersions
                        }
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>

        {/* Inputting additional information */}
        <Grid
          container
          width="100%"
          display={step === 3 ? "block" : "none"}
          spacing={2}
          sx={{ marginBottom: "10rem" }}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Step 2: Provide additional information
                </MDTypography>
              </MDBox>

              <MDBox sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <MDBox mt={2} mb={5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    sx={{ marginBottom: "0.3rem", display: "block" }}
                  >
                    <b>Name of Auditor Report:</b>
                  </MDTypography>
                  <TextField
                    label="Enter Auditor Report Name"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    onChange={(e) => setSspName(e.target.value)}
                    InputLabelProps={{
                      sx: {
                        fontWeight: 700,
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontWeight: 700,
                      },
                    }}
                  />
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={{ marginBottom: "0.3rem", display: "block" }}
                  >
                    <b>Introduction Description:</b>
                  </MDTypography>
                  <Editor
                    apiKey={TINYMC_API_KEY}
                    value={introductionDescription}
                    init={{
                      branding: false,
                      height: 400,
                      menubar: false,
                      advcode_inline: true,
                      automatic_uploads: true,
                    }}
                    onEditorChange={(newValue, editor) => {
                      setIntroductionDescription(newValue);
                    }}
                    plugins={[
                      "anchor",
                      "autolink",
                      "advcode",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "linkchecker",
                      // "a11ychecker",
                      // "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      "fullscreen",
                      "inlinecss",
                    ]}
                    toolbar={[
                      "fullscreen undo redo | blocks  | " +
                        "bold italic underline strikethrough | removeformat code",
                      "link table | spellcheckdialog a11ycheck | align lineheight | " +
                        "checklist numlist bullist indent outdent ",
                    ]}
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* choosing auditor */}
        <Grid
          container
          width="100%"
          display={step === 4 ? "block" : "none"}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
              }}
            >
              <MDBox
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "2rem",
                  width: "100%",
                }}
              >
                <MDTypography
                  variant="p"
                  fontWeight="bold"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Step 4: Select An Auditor For This Report
                </MDTypography>
              </MDBox>

              <MDBox sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <Grid item xs={12} pt={0}>
                  <MDBox mb={3}>
                    <MDBox mb={2} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Auditor
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      options={auditors}
                      getOptionLabel={(option) =>
                        option.name + " " + option.last_name
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setSelectedAuditor("");
                        } else {
                          setSelectedAuditor(value);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <MDBox
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "2rem",
            display: "flex",
            // flexDirection: "column",
            // width: "18%",
            gap: 2,
            zIndex: 3,
          }}
        >
          <MDButton
            color="info"
            onClick={() => handleSetStep2()}
            sx={{
              padding: "20px",
              display: step === 1 ? "block" : "none",
            }}
          >
            Step 2: Choose Assessment Version
          </MDButton>

          {isGeneratingSsp === false ? (
            <>
              <MDButton
                color="primary"
                onClick={() => {
                  setIntroductionDescription(introductionDescriptionUnchanged);
                  setAllTrackedAssessmentVersions(
                    allTrackedAssessmentVersionsOriginal
                  );
                  setStep(1);
                }}
                sx={{
                  padding: "20px",
                  display: step !== 1 ? "block" : "none",
                }}
              >
                Back to assessment selection
              </MDButton>
              <MDButton
                color="success"
                // onClick={() => btnOnClickOpenModalForInputtingSspName()}
                type="submit"
                onClick={() => btnOnClickgenerateSsp()}
                sx={{
                  padding: "20px",
                  display: step === 4 ? "block" : "none",
                }}
              >
                Generate Auditor Report
              </MDButton>
            </>
          ) : (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          )}
          <MDButton
            color="info"
            onClick={() => handleSetStep3()}
            sx={{
              padding: "20px",
              display: step === 2 ? "block" : "none",
            }}
          >
            Step 3: Provide Additional Information
          </MDButton>
          <MDButton
            color="info"
            onClick={() => handleSetStep4()}
            sx={{
              padding: "20px",
              display: step === 3 ? "block" : "none",
            }}
          >
            Step 4: Select An Auditor
          </MDButton>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Index;
