import { useState } from "react";
import { Typography, Box } from "@mui/material";
import MDButton from "components/MDButton";

const FileUpload = ({ onFileSelect }) => {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setError(null); // Clear previous error

    if (file) {
      // Validate file type
      if (!file.name.endsWith(".csv")) {
        setError("Invalid file type. Please upload a .csv file.");
        setSelectedFileName(null);
        return;
      }

      // Validate file size (example: max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        setError("File size exceeds 5MB. Please upload a smaller file.");
        setSelectedFileName(null);
        return;
      }

      // Successful file selection
      setSelectedFileName(file.name);
      onFileSelect(file); // Pass the file to the parent component's handler
    } else {
      setSelectedFileName(null);
    }
  };

  return (
    <Box>
      <MDButton
        component="label"
        variant="contained"
        color="info"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "none",
          padding: "10px 20px",
          marginBottom: "10px",
        }}
      >
        Upload CSV File
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </MDButton>

      {selectedFileName && (
        <Typography variant="subtitle2" color="success.main">
          Selected File: {selectedFileName}
        </Typography>
      )}

      {error && (
        <Typography variant="subtitle2" color="error.main">
          {error}
        </Typography>
      )}

      {!selectedFileName && !error && (
        <Typography variant="subtitle2" color="text.secondary">
          No file selected. Please upload a .csv file.
        </Typography>
      )}
    </Box>
  );
};

export default FileUpload;
