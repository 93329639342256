import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate, useLocation } from "react-router-dom";

const CustomerNameBtnTr = (props) => {
  const { value, column, row } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};

  const btnOnClickClientName = (objClientData) => {
    routeDictionary[objClientData.element.id] = value;
    navigate(`/clients/${objClientData.element.id}/edit`, {
      state: { routeDictionary },
    });
  };

  return (
    <>
      {row.original.is_msp_client && (
        <MDTypography
          sx={{
            fontSize: "0.75rem",
            backgroundColor: "#3498db",
            padding: "0.25rem 0.5rem",
            borderRadius: "0.25rem",
            display: "inline-block",
            marginRight: "10px",
          }}
          color="white"
        >
          MSP Client
        </MDTypography>
      )}
      <MDButton
        variant="text"
        onClick={() => btnOnClickClientName(row.original)}
      >
        <MDTypography
          variant="text"
          fontWeight="medium"
          sx={{
            lineHeight: 0,
            color: row.original.is_msp_client ? "#3498db" : "#38b54a",
          }}
        >
          {value}
        </MDTypography>
      </MDButton>
    </>
  );
};

export default CustomerNameBtnTr;
