import MDBox from "components/MDBox";
import DefaultCell from "views/components/TableCells/DefaultCell";

let OtherReviewsDataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (
        <MDBox
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "200px",
          }}
        >
          <DefaultCell value={value} />
        </MDBox>
      ),
    },
    {
      Header: "Company",
      accessor: "company_name",
      Cell: ({ value, column, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Submitted",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact",
      accessor: "contact",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default OtherReviewsDataTableData;
