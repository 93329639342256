// react-router-dom components

import Grid from "@mui/material/Grid";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import EditCompanyUserForm from "./components/EditCompanyUserForm";

const EditCompanyUser = (props) => {
  const { objUser } = props;

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container justifyContent="center" alignItems="center" width="100%">
        <Grid item xs={12} lg={12}>
          <EditCompanyUserForm objUser={objUser} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EditCompanyUser;
