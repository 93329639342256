import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { cloneTaskListTemplate } from "features/company/taskListActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";

const TaskListFormSchema = Yup.object().shape({
  txtTitle: Yup.string().required("The Name is required."),
  txtNotes: Yup.string(),
  dueDate: Yup.date().nullable(),
});

const CloneTaskListDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskListTemplate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const cloneTemplateData = {
      title: values.txtTitle,
      notes: values.txtNotes,
      due_date: values.dueDate,
      global_task_list_id: taskListTemplate.id,
    };

    try {
      setIsLoading(true);
      const originalPromiseResult = await dispatch(
        cloneTaskListTemplate({
          objUser,
          cloneTemplateData,
        })
      ).then(unwrapResult);

      if (!originalPromiseResult || originalPromiseResult.status !== 200) {
        throw new Error("Clone task list template request failed.");
      }

      showMsg("success", "Task List Template cloned successfully");
      onSubmit(originalPromiseResult.data)
    } catch (error) {
      console.error("Error cloning task list template:", error);
      showMsg("error", "Something went wrong, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Create Task List</DialogTitle>
      <Formik
        initialValues={{
          txtTitle: taskListTemplate?.title ?? "",
          txtNotes: taskListTemplate?.notes ?? "",
          dueDate: taskListTemplate?.due_date ?? null,
        }}
        validationSchema={TaskListFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched }) => (
          <Form id="TaskListForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="text"
                  label="Title"
                  name="txtTitle"
                  value={values.txtTitle}
                  placeholder="Task List Title"
                  error={errors.txtTitle && touched.txtTitle}
                  success={values.txtTitle.length > 0 && !errors.txtTitle}
                />
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="textarea"
                  label="Notes"
                  name="txtNotes"
                  value={values.txtNotes}
                  placeholder="Notes"
                  error={errors.txtNotes && touched.txtNotes}
                  success={values.txtNotes?.length > 0 && !errors.txtNotes}
                />
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="date"
                  label="Due Date"
                  name="dueDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.dueDate}
                  error={errors.dueDate && touched.dueDate}
                  success={values.dueDate && !errors.dueDate}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Clone
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CloneTaskListDialog;
